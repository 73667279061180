var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-5 limites_taxas " },
    [
      _vm.profileAllowed
        ? _c(
            "div",
            { staticClass: "text-right" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.openAdicionarPremio()
                    }
                  }
                },
                [
                  _vm._v("Adicionar condição\n      "),
                  _c("i", { staticClass: "onpoint-plus" })
                ]
              ),
              _c("br"),
              _c("br")
            ],
            1
          )
        : _vm._e(),
      _vm.listaPremios.length > 0
        ? [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.listaPremios }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "Modalidade" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "10px" } },
                              [
                                _vm._v(
                                  "\n      \n          " +
                                    _vm._s(
                                      scope.row.ModalityName +
                                        " (" +
                                        scope.row.BranchName +
                                        ")"
                                    )
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1052214974
                  )
                }),
                _c("el-table-column", {
                  attrs: { label: "Tipo", width: "200px" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  scope.row.MinInsurancePremium
                                    ? "Prêmio mínimo"
                                    : "Prêmio fixo"
                                ) +
                                "\n        "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3800650190
                  )
                }),
                _c("el-table-column", {
                  attrs: { label: "Valor", width: "200px" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  scope.row.MinInsurancePremium
                                    ? scope.row.MinInsurancePremium.toLocaleString(
                                        "pt-br",
                                        {
                                          style: "currency",
                                          currency: "BRL"
                                        }
                                      )
                                    : scope.row.FixedInsurancePremium.toLocaleString(
                                        "pt-br",
                                        {
                                          style: "currency",
                                          currency: "BRL"
                                        }
                                      )
                                ) +
                                "\n        "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2677136943
                  )
                }),
                _vm.profileAllowed
                  ? _c("el-table-column", {
                      attrs: { label: "Ações", width: "200px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.editarPremio(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("Editar")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deletarPremio(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("Deletar")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1682150689
                      )
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        : [_vm._m(0)],
      _c(
        "vs-popup",
        {
          staticClass: "editar-grupo-modalidade",
          attrs: {
            title: "Adicionar condição",
            active: _vm.modalAdicionarPremio
          },
          on: {
            "update:active": function($event) {
              _vm.modalAdicionarPremio = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _c("el-alert", {
                attrs: {
                  title:
                    "Selecione a modalidade e digite um prêmio fixo ou mínimo.",
                  type: "warning",
                  closable: false
                }
              }),
              _c("span", { staticClass: "mt-3" }, [_vm._v("Modalidade")]),
              _c(
                "el-select",
                {
                  staticClass: "mt-3",
                  attrs: {
                    filterable: "",
                    placeholder: "Escolha a modalidade"
                  },
                  model: {
                    value: _vm.modalidadeSelecionada,
                    callback: function($$v) {
                      _vm.modalidadeSelecionada = $$v
                    },
                    expression: "modalidadeSelecionada"
                  }
                },
                _vm._l(_vm.groupList, function(item) {
                  return _c("el-option", {
                    key: item.Id,
                    attrs: {
                      label:
                        item.Name + " (" + item.ModalityGroup.Branch.Name + ")",
                      value: item.Id,
                      disabled: item.disabled
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "vx-row mt-6" }, [
            _c(
              "div",
              { staticClass: "vx-col w-1/2" },
              [
                _c("p", [_vm._v("Prêmio fixo")]),
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input large hasValue",
                  on: {
                    change: function($event) {
                      _vm.premioMinimo = null
                    }
                  },
                  model: {
                    value: _vm.premioFixo,
                    callback: function($$v) {
                      _vm.premioFixo = $$v
                    },
                    expression: "premioFixo"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-1/2" },
              [
                _c("p", [_vm._v("Prêmio Mínimo")]),
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input large hasValue",
                  on: {
                    change: function($event) {
                      _vm.premioFixo = null
                    }
                  },
                  model: {
                    value: _vm.premioMinimo,
                    callback: function($$v) {
                      _vm.premioMinimo = $$v
                    },
                    expression: "premioMinimo"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "vs-row mt-5" },
            [
              _vm.errorModal
                ? _c("el-alert", {
                    attrs: {
                      title: "Erro",
                      type: "error",
                      description: _vm.errorMensage,
                      closable: false,
                      "show-icon": ""
                    }
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "vx-col text-right mt-6" },
                [
                  _c(
                    "vs-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.adicionarCondicao()
                        }
                      }
                    },
                    [_vm._v("\n          Salvar alterações\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "editar-grupo-modalidade",
          attrs: {
            title: "Editar Condição  da  Modalidade - ",
            active: _vm.editarGrupoModalidadePopup
          },
          on: {
            "update:active": function($event) {
              _vm.editarGrupoModalidadePopup = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _c("span", { staticClass: "mt-3" }, [_vm._v("Modalidade")]),
              _c(
                "el-select",
                {
                  staticClass: "mt-3",
                  attrs: {
                    disabled: "",
                    filterable: "",
                    placeholder: "Escolha a modalidade"
                  },
                  model: {
                    value: _vm.modalidadeSelecionada,
                    callback: function($$v) {
                      _vm.modalidadeSelecionada = $$v
                    },
                    expression: "modalidadeSelecionada"
                  }
                },
                _vm._l(_vm.groupList, function(item) {
                  return _c("el-option", {
                    key: item.Id,
                    attrs: {
                      label:
                        item.Name + " (" + item.ModalityGroup.Branch.Name + ")",
                      value: item.Id
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "vx-row mt-6" }, [
            _c(
              "div",
              { staticClass: "vx-col w-1/2" },
              [
                _c("p", [_vm._v("Prêmio fixo")]),
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input large hasValue",
                  on: {
                    change: function($event) {
                      _vm.premioMinimo = null
                    }
                  },
                  model: {
                    value: _vm.premioFixo,
                    callback: function($$v) {
                      _vm.premioFixo = $$v
                    },
                    expression: "premioFixo"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-1/2" },
              [
                _c("p", [_vm._v("Prêmio Mínimo")]),
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input large hasValue",
                  on: {
                    change: function($event) {
                      _vm.premioFixo = null
                    }
                  },
                  model: {
                    value: _vm.premioMinimo,
                    callback: function($$v) {
                      _vm.premioMinimo = $$v
                    },
                    expression: "premioMinimo"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "vs-row mt-5" },
            [
              _vm.errorModal
                ? _c("el-alert", {
                    attrs: {
                      title: "Erro",
                      type: "error",
                      description: _vm.errorMensage,
                      closable: false,
                      "show-icon": ""
                    }
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "vx-col text-right mt-6" },
                [
                  _c(
                    "vs-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.salvarCondicao()
                        }
                      }
                    },
                    [_vm._v("\n          Salvar alterações\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto items-center my-32"
      },
      [
        _c("i", { staticClass: "onpoint-file-text" }),
        _c("p", { staticClass: "text-center" }, [
          _vm._v(
            "\n        Não há registros desse tomador disponíveis para visualização.\n      "
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }