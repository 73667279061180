var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "mt-4" }, [
    _c(
      "div",
      { staticClass: "table-content new-history" },
      [
        _c("h2", { staticClass: "font-bold" }, [
          _vm._v("\n      Historico de Alterações\n    ")
        ]),
        _vm.historico.length > 0
          ? [
              _c(
                "vs-table",
                {
                  attrs: {
                    data: _vm.historico,
                    hoverFlat: true,
                    noDataText: "Nenhuma histórico encontrado"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var data = ref.data
                          return _vm._l(data, function(item, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr, attrs: { data: item } },
                              [
                                _c(
                                  "vs-td",
                                  {
                                    attrs: { data: data[indextr].ChangedByName }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.ChangedByName || "-") +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  {
                                    staticClass: "text-left",
                                    attrs: { data: item.ChangedAt }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("moment")(
                                            item.ChangedAt,
                                            "DD/MM/YYYY"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  { staticClass: "text-right" },
                                  [
                                    _c(
                                      "vs-dropdown",
                                      {
                                        staticClass: "btn-action-apolice",
                                        attrs: {
                                          "vs-custom-content": "",
                                          "vs-trigger-click": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            attrs: {
                                              color: "dark",
                                              type: "flat",
                                              title: "Ações"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "onpoint-dots-three-vertical icon-dots-three-vertical",
                                              attrs: { role: "button" }
                                            })
                                          ]
                                        ),
                                        _c(
                                          "vs-dropdown-menu",
                                          {
                                            staticClass:
                                              "btn-action-apolice-options"
                                          },
                                          [
                                            _c("vs-dropdown-item", [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "text-base text-nowrap",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openModalAlteracoes(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Visualizar alterações"
                                                    )
                                                  ])
                                                ]
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        }
                      }
                    ],
                    null,
                    false,
                    469585603
                  )
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("Usuário")]),
                      _c("vs-th", { staticClass: "text-center" }, [
                        _vm._v("Data da alteração")
                      ]),
                      _c("vs-th")
                    ],
                    1
                  )
                ],
                2
              ),
              _vm.numberOfPages > 1
                ? _c("vs-pagination", {
                    staticClass: "mt-5",
                    attrs: { total: _vm.numberOfPages },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                : _vm._e(),
              _c(
                "el-dialog",
                {
                  staticClass: "tomador-historico-alteracao",
                  attrs: {
                    id: "listar-alteracoes",
                    title: "Alterações realizadas pelo usuário",
                    visible: _vm.showModal
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.showModal = $event
                    }
                  }
                },
                [
                  _c("div", { staticStyle: { "overflow-y": "auto" } }, [
                    _c(
                      "div",
                      { staticClass: " new-history" },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: _vm.alteracoesVisializacao,
                              hoverFlat: true,
                              noDataText: "Nenhuma alteração encontrada"
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "Column", label: "Coluna" }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "PreviousValue", label: "Antes" }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "NewValue", label: "Depois" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "ChangedTime",
                                label: "Hora da alteração"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.setMascaraHoras(
                                                scope.row.ChangedTime
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3857329856
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ]
          : [_vm._m(0)]
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto items-center mt-32"
      },
      [
        _c("i", { staticClass: "onpoint-file-text" }),
        _c("p", { staticClass: "text-center" }, [
          _vm._v(
            "\n          Nenhuma alteração foi realizada para este tomador\n        "
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }