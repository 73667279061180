var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "representatives-container" }, [
    _c("h4", { staticClass: "font-bold text-primary" }, [_vm._t("header")], 2),
    _c(
      "div",
      { staticClass: "parts" },
      [
        _c("div", { staticClass: "header" }, [
          _vm.tipoParteName === "Fiador Pessoa Jurídica"
            ? _c("ul", { staticClass: "header-content-pj" }, [
                _c("li", [_vm._v("CNPJ")]),
                _c("li", [_vm._v("Razão Social")]),
                _c("li", [_vm._v("CPF do Representante")]),
                _c("li", [_vm._v("Nome do Representante")]),
                _c("li", [_vm._v("E-mail do Representante")]),
                _c("li")
              ])
            : _c("ul", { staticClass: "header-content" }, [
                _c("li", [_vm._v("CPF")]),
                _c("li", [_vm._v("Nome")]),
                _c("li", [_vm._v("E-mail")]),
                _c("li")
              ])
        ]),
        _vm._l(_vm.partes, function(parte, index) {
          return _c("ul", { key: index, staticClass: "part" }, [
            _c("div", { staticClass: "part-wrapper" }, [
              _vm.tipoParteName === "Representante Legal" ||
              _vm.tipoParteName === "Fiador Pessoa Física" ||
              _vm.tipoParteName === "Testemunha" ||
              _vm.tipoParteName === "Seguradora"
                ? _c(
                    "li",
                    { staticClass: "part-infos" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "part-infos-status",
                          class:
                            "status-" + parte.TipoParteStatusNome.toLowerCase(),
                          on: {
                            mouseenter: function($event) {
                              _vm.mostrarTooltip = true
                            },
                            mouseleave: function($event) {
                              _vm.mostrarTooltip = false
                            }
                          }
                        },
                        [
                          (_vm.tipoParteName === "Seguradora"
                          ? _vm.showAssingSecurity
                          : true)
                            ? _c(
                                "div",
                                {
                                  staticClass: "part-infos-status-notice",
                                  class:
                                    "status-" +
                                    parte.TipoParteStatusNome.toLowerCase()
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(parte.TipoParteStatusNome) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _c("p", [_vm._v(_vm._s(parte.CPFMask))]),
                      _c("div", { staticClass: "divider" }),
                      _c("p", [_vm._v(_vm._s(parte.Nome))]),
                      _c("div", { staticClass: "divider" }),
                      parte.Email
                        ? _c("p", { staticClass: "part-infos-email" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(parte.Email) +
                                "\n          "
                            )
                          ])
                        : _c("p", { staticClass: "part-infos-warning" }, [
                            _vm._v(
                              "\n            Nenhum e-mail cadastrado\n          "
                            )
                          ]),
                      _vm.tipoParteName !== "Seguradora"
                        ? _c("div", { staticClass: "divider" })
                        : _vm._e(),
                      _vm.tipoParteName !== "Seguradora"
                        ? _c("ListaAcoes", {
                            attrs: {
                              parte: parte,
                              permissoes: _vm.permissoes,
                              conjugeHabilitado: _vm.conjugeHabilitado,
                              contratoEspecificacoes: _vm.contratoEspecificacoes
                            },
                            on: {
                              edicaoDadosSelecionado: function($event) {
                                return _vm.encaminharEdicaoDados(parte)
                              },
                              cadastrarConjugeSelecionado: function($event) {
                                return _vm.encaminharAdicaoConjuge(parte)
                              },
                              reenviarEmailSelecionado: function($event) {
                                return _vm.encaminharReenviarEmail(parte)
                              },
                              copiarLinkAssinaturaSelecionado: function(
                                $event
                              ) {
                                return _vm.encaminharCopiarLinkAssinatura(parte)
                              },
                              excluirRepresentanteSelecionado: function(
                                $event
                              ) {
                                return _vm.encaminharExcluirRepresentante(parte)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              parte.Conjuge && _vm.conjugeHabilitado
                ? _c(
                    "li",
                    { staticClass: "part-infos-spouse" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "part-infos-spouse-status",
                          class:
                            "status-" + parte.TipoParteStatusNome.toLowerCase(),
                          on: {
                            mouseenter: function($event) {
                              _vm.mostrarTooltip = true
                            },
                            mouseleave: function($event) {
                              _vm.mostrarTooltip = false
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "part-infos-spouse-status-notice",
                              class:
                                "status-" +
                                parte.TipoParteStatusNome.toLowerCase()
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(parte.TipoParteStatusNome) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      ),
                      _c("p", [_vm._v(_vm._s(parte.Conjuge.CPF))]),
                      _c("div", { staticClass: "divider" }),
                      _c(
                        "div",
                        {
                          staticClass: "part-infos-spouse-status-notice-tooltip"
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "light",
                                content: "Cônjuge",
                                placement: "top"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline"
                              })
                            ]
                          ),
                          _c("p", [_vm._v(_vm._s(parte.Conjuge.Nome))])
                        ],
                        1
                      ),
                      _c("div", { staticClass: "divider" }),
                      parte.Conjuge.Email
                        ? _c("p", { staticClass: "part-infos-spouse-email" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(parte.Conjuge.Email) +
                                "\n          "
                            )
                          ])
                        : _c(
                            "p",
                            { staticClass: "part-infos-spouse-warning" },
                            [
                              _vm._v(
                                "\n            Nenhum e-mail cadastrado\n          "
                              )
                            ]
                          ),
                      _c("div", { staticClass: "divider" }),
                      _c("ListaAcoes", {
                        attrs: {
                          parte: parte.Conjuge,
                          permissoes: _vm.permissoes,
                          contratoEspecificacoes: _vm.contratoEspecificacoes
                        },
                        on: {
                          edicaoDadosSelecionado: function($event) {
                            return _vm.encaminharEdicaoDados(parte.Conjuge)
                          },
                          reenviarEmailSelecionado: function($event) {
                            return _vm.encaminharReenviarEmail(parte)
                          },
                          copiarLinkAssinaturaSelecionado: function($event) {
                            return _vm.encaminharCopiarLinkAssinatura(
                              parte.Conjuge
                            )
                          },
                          excluirRepresentanteSelecionado: function($event) {
                            return _vm.encaminharExcluirRepresentante(
                              parte.Conjuge
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              parte.TipoParteName === "Fiador Pessoa Jurídica"
                ? _c(
                    "li",
                    { staticClass: "part-infos-pj" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "part-infos-pj-status",
                          class:
                            "status-" + parte.TipoParteStatusNome.toLowerCase(),
                          on: {
                            mouseenter: function($event) {
                              _vm.mostrarTooltip = true
                            },
                            mouseleave: function($event) {
                              _vm.mostrarTooltip = false
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "part-infos-pj-status-notice",
                              class:
                                "status-" +
                                parte.TipoParteStatusNome.toLowerCase()
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(parte.TipoParteStatusNome) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      ),
                      _c("p", [_vm._v(_vm._s(parte.CNPJMask))]),
                      _c("div", { staticClass: "divider" }),
                      _c("p", [_vm._v(_vm._s(parte.NomeEmpresa))]),
                      _c("div", { staticClass: "divider" }),
                      _c("p", [_vm._v(_vm._s(parte.CPFMask))]),
                      _c("div", { staticClass: "divider" }),
                      _c("p", [_vm._v(_vm._s(parte.Nome))]),
                      _c("div", { staticClass: "divider" }),
                      parte.Email
                        ? _c("p", { staticClass: "part-infos-pj-email" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(parte.Email) +
                                "\n          "
                            )
                          ])
                        : _c("p", { staticClass: "part-infos-pj-warning" }, [
                            _vm._v(
                              "\n            Nenhum e-mail cadastrado\n          "
                            )
                          ]),
                      _c("ListaAcoes", {
                        attrs: {
                          parte: parte,
                          permissoes: _vm.permissoes,
                          contratoEspecificacoes: _vm.contratoEspecificacoes
                        },
                        on: {
                          edicaoDadosSelecionado: function($event) {
                            return _vm.encaminharEdicaoDados(parte)
                          },
                          reenviarEmailSelecionado: function($event) {
                            return _vm.encaminharReenviarEmail(parte)
                          },
                          copiarLinkAssinaturaSelecionado: function($event) {
                            return _vm.encaminharCopiarLinkAssinatura(parte)
                          },
                          excluirRepresentanteSelecionado: function($event) {
                            return _vm.encaminharExcluirRepresentante(parte)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        })
      ],
      2
    ),
    _vm.tipoParteName !== "Seguradora"
      ? _c(
          "div",
          { staticClass: "add-rep" },
          [
            _c(
              "el-button",
              {
                staticClass: "add-rep-btn",
                attrs: {
                  type: "primary",
                  disabled:
                    !_vm.deveExibirAdicionarParte(_vm.tipoParteName) ||
                    _vm.contratoEspecificacoes.StatusId !== 1
                },
                on: {
                  click: function($event) {
                    return _vm.encaminharAdicaoParte(
                      _vm.tipoParteId,
                      _vm.tipoParteName
                    )
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-user" }), _vm._t("button")],
              2
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }