var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-4 py-4" },
    [
      _c("div", { staticClass: "tab" }, [
        _c(
          "button",
          {
            staticClass: "tabLinks cursor-pointer active font-medium w-auto",
            on: {
              click: function(e) {
                return _vm.openTab(e, "dadosCadastrais")
              }
            }
          },
          [_vm._v("\n      Dados Cadastrais\n    ")]
        ),
        _c(
          "button",
          {
            staticClass: "tabLinks cursor-pointer font-medium w-auto",
            on: {
              click: function(e) {
                return _vm.openTab(e, "dadosComplementares")
              }
            }
          },
          [_vm._v("\n      Dados Complementares\n    ")]
        ),
        _vm.profileHelpers.isInsurance() &&
        _vm.profileHelpers.checkPermission([
          _vm.profileHelpers.menuFuncionalitiesEnum
            .VISUALIZAR_ANALISE_DE_CREDITO
        ])
          ? _c(
              "button",
              {
                staticClass: "tabLinks cursor-pointer font-medium w-auto",
                on: {
                  click: function(e) {
                    return _vm.openTab(e, "analiseCredito")
                  }
                }
              },
              [_vm._v("\n      Análise de Crédito\n    ")]
            )
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "tabLinks cursor-pointer font-medium w-auto",
            on: {
              click: function(e) {
                return _vm.openTab(e, "dadosBancarios")
              }
            }
          },
          [_vm._v("\n      Dados Bancários\n    ")]
        )
      ]),
      _c(
        "div",
        { staticClass: "tabContent active", attrs: { id: "dadosCadastrais" } },
        [
          _vm.profileHelpers.checkPermission([
            _vm.profileHelpers.menuFuncionalitiesEnum.APROVACAO_MANUAL,
            _vm.profileHelpers.menuFuncionalitiesEnum.REPROCESSAMENTO,
            _vm.profileHelpers.menuFuncionalitiesEnum.IGNORAR_ASSINATURA_DE_CCG
          ])
            ? _c(
                "vs-row",
                { staticClass: "mt-6", attrs: { "vs-type": "inline-flex" } },
                [
                  _vm.profileHelpers.checkPermission([
                    _vm.profileHelpers.menuFuncionalitiesEnum.APROVACAO_MANUAL
                  ]) && _vm.tomadorDetail.ApprovedByCreditEngine == false
                    ? _c(
                        "vs-col",
                        { staticClass: "w-auto" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success" },
                              on: {
                                click: function($event) {
                                  _vm.openModalForcarAprovacao = true
                                }
                              }
                            },
                            [_vm._v("Aprovar Manual")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.profileHelpers.checkPermission([
                    _vm.profileHelpers.menuFuncionalitiesEnum.REPROCESSAMENTO
                  ])
                    ? _c(
                        "vs-col",
                        { staticClass: "w-auto" },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: {
                                color: "primary",
                                type: "border",
                                disabled: !_vm.permiteReprocessamento
                              },
                              on: {
                                click: function($event) {
                                  _vm.openModalReprocessamento = true
                                }
                              }
                            },
                            [_vm._v("Reprocessamento")]
                          ),
                          !_vm.permiteReprocessamento
                            ? _c(
                                "vx-tooltip",
                                {
                                  staticClass: "enable-ccg",
                                  attrs: {
                                    text:
                                      "Desabilitado pois tomador já possui apólices emitidas",
                                    position: "right"
                                  }
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("info")
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.profileHelpers.checkPermission([
                    _vm.profileHelpers.menuFuncionalitiesEnum
                      .IGNORAR_ASSINATURA_DE_CCG
                  ])
                    ? _c(
                        "vs-col",
                        { staticClass: "w-1/2 flex text-right" },
                        [
                          _c("label", [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.verificarMapfreCorretor()
                                    ? " CCG regularizado"
                                    : " Ignorar assinatura de CCG"
                                ) +
                                "\n        "
                            )
                          ]),
                          _c(
                            "vx-tooltip",
                            {
                              staticClass: "enable-ccg",
                              attrs: {
                                text:
                                  "Ao ativar essa opção, a assinatura do CCG não será obrigatória para emitir propostas",
                                position: "right"
                              }
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("info")
                              ])
                            ]
                          ),
                          _c(
                            "vs-switch",
                            {
                              staticClass: "ml-2",
                              on: {
                                click: function($event) {
                                  return _vm.invertCcgSignature(
                                    _vm.tomadorDetail.UniqueId
                                  )
                                }
                              },
                              model: {
                                value: _vm.tomadorDetail.IgnoreCCGSignature,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.tomadorDetail,
                                    "IgnoreCCGSignature",
                                    $$v
                                  )
                                },
                                expression: "tomadorDetail.IgnoreCCGSignature"
                              }
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "on" }, slot: "on" },
                                [_vm._v("Sim")]
                              ),
                              _c(
                                "span",
                                { attrs: { slot: "off" }, slot: "off" },
                                [_vm._v("Não")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      disabled: "true",
                      label: "Razão Social",
                      value: _vm.tomadorDetail.Name,
                      icon:
                        _vm.podeEditar && _vm.profileHelpers.isInsurance()
                          ? "mode_edit"
                          : "",
                      size: "large"
                    },
                    on: {
                      "icon-click": function($event) {
                        return _vm.openModalUpdateName()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                { staticClass: "w-1/4" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: ["##.###.###/####-##"],
                        expression: "['##.###.###/####-##']"
                      }
                    ],
                    attrs: {
                      label: "CNPJ",
                      disabled: "true",
                      value: _vm.tomadorDetail.CpfCnpj,
                      size: "large"
                    }
                  })
                ],
                1
              ),
              _c(
                "vs-col",
                { staticClass: "w-1/4" },
                [
                  _vm.tomador.CreateDate != undefined
                    ? _c("vs-input", {
                        attrs: {
                          label: "Data de registro",
                          disabled: "true",
                          value: _vm._f("dateFormat")(
                            new Date((_vm.tomador || {}).CreateDate),
                            "DD/MM/YYYY"
                          ),
                          size: "large"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "vs-col",
                { staticClass: "w-1/4" },
                [
                  _vm.profileHelpers.isInsurance()
                    ? _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          disabled: "true",
                          label: "Data de validade cadastral",
                          icon: _vm.podeEditar ? "mode_edit" : "",
                          value: _vm._f("dateFormat")(
                            new Date(_vm.tomadorDetail.ExpirationDate),
                            "DD/MM/YYYY"
                          ),
                          size: "large"
                        },
                        on: {
                          "icon-click": function($event) {
                            return _vm.openModalUpdateDate()
                          }
                        }
                      })
                    : _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          disabled: "true",
                          label: "Data de validade cadastral",
                          value: _vm._f("dateFormat")(
                            new Date(_vm.tomadorDetail.ExpirationDate),
                            "DD/MM/YYYY"
                          ),
                          size: "large"
                        }
                      })
                ],
                1
              ),
              _c(
                "vs-col",
                { staticClass: "w-1/4" },
                [
                  _vm.tomadorDetail.LimitsAndRatesRecalculatedDate
                    ? _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          label: "Data recadastro",
                          disabled: "true",
                          value: _vm._f("dateFormat")(
                            new Date(
                              (
                                _vm.tomadorDetail || {}
                              ).LimitsAndRatesRecalculatedDate
                            ),
                            "DD/MM/YYYY"
                          ),
                          size: "large"
                        }
                      })
                    : _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          label: "Data recadastro",
                          disabled: "true",
                          value: "Sem registro",
                          size: "large"
                        }
                      })
                ],
                1
              )
            ],
            1
          ),
          _vm.profileHelpers.isInsurance()
            ? _c(
                "vs-row",
                [
                  _c(
                    "vs-col",
                    { staticClass: "w-1/4" },
                    [
                      _c("vs-input", {
                        attrs: {
                          disabled: "true",
                          label: "Comissão",
                          value: _vm.tomadorDetail.ComissionPercentage + "%",
                          icon: _vm.podeEditar ? "mode_edit" : "",
                          size: "large"
                        },
                        on: {
                          "icon-click": function($event) {
                            return _vm.openModalUpdateComission()
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    { staticClass: "w-1/2" },
                    [
                      _c("vs-input", {
                        attrs: {
                          disabled: "true",
                          label: "Corretor Nomeado",
                          value: _vm.tomadorDetail.NominatedToBroker
                            ? _vm.tomadorDetail.NominatedToBroker.Name
                            : "Sem nomeação",
                          icon: _vm.podeEditar ? "mode_edit" : "",
                          size: "large"
                        },
                        on: {
                          "icon-click": function($event) {
                            return _vm.exibirModalOpcoesCoretorNomeado()
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    { staticClass: "w-1/4" },
                    [
                      _c("vs-input", {
                        attrs: {
                          disabled: "true",
                          label: "Data de validade da nomeação",
                          value: _vm.tomadorDetail.NomeationExpireDate
                            ? _vm.formatarData(
                                _vm.tomadorDetail.NomeationExpireDate
                              )
                            : "-",
                          size: "large"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.profileHelpers.isInsurance()
            ? [
                _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      { staticClass: "w-1/3" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label: "Data de fundação",
                            disabled: "true",
                            value: _vm.dadosDoRisco.CompanyInaugurationDate
                              ? _vm.formatarData(
                                  _vm.dadosDoRisco.CompanyInaugurationDate
                                )
                              : "-",
                            size: "large"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { staticClass: "w-1/3" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label: "Obs. Fundação",
                            disabled: "true",
                            value:
                              _vm.dadosDoRisco.CompanyInaugurationDescription,
                            size: "large"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { staticClass: "w-1/3" },
                      [
                        _c("vs-input", {
                          attrs: {
                            label: "Situação",
                            disabled: "true",
                            value: "ATIVO",
                            size: "large",
                            icon:
                              _vm.userInfo.ProfileName === "RiskAdviser"
                                ? "mode_edit"
                                : ""
                          },
                          on: {
                            "icon-click": function($event) {
                              _vm.consultarWSDialog = !_vm.consultarWSDialog
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      { staticClass: "w-1/3" },
                      [
                        _c("vs-input", {
                          attrs: {
                            label: "CNAE ",
                            disabled: "true",
                            value: _vm.filterCNAEs(_vm.tomadorDetail.CNAEs),
                            size: "large"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { staticClass: "w-1/3" },
                      [
                        _c("vs-input", {
                          attrs: {
                            label: "Ramo de atuação",
                            disabled: "true",
                            value: _vm.dadosDoRisco.BranchActivity,
                            size: "large"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { staticClass: "w-1/3" },
                      [
                        _c("vs-input", {
                          attrs: {
                            label: "Ramo/CNAE Restrito?",
                            disabled: "true",
                            value: _vm.dadosDoRisco.BranchActivityIsRestrictive
                              ? "Sim"
                              : "Não",
                            size: "large"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      { staticClass: "w-1/3" },
                      [
                        _c("p", [_vm._v("Fat. Presumido Anual")]),
                        _c("currency-input", {
                          staticClass:
                            "w-full vs-inputx vs-input--input large hasValue",
                          attrs: { disabled: "true" },
                          model: {
                            value: _vm.dadosDoRisco.AnnualBillingAmount,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.dadosDoRisco,
                                "AnnualBillingAmount",
                                $$v
                              )
                            },
                            expression: "dadosDoRisco.AnnualBillingAmount"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { staticClass: "w-1/3" },
                      [
                        _c("p", [_vm._v("Capital Social")]),
                        _c("currency-input", {
                          staticClass:
                            "w-full vs-inputx vs-input--input large hasValue",
                          attrs: { disabled: "true" },
                          model: {
                            value: _vm.dadosDoRisco.ShareCapitalValue,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.dadosDoRisco,
                                "ShareCapitalValue",
                                $$v
                              )
                            },
                            expression: "dadosDoRisco.ShareCapitalValue"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { staticClass: "w-1/3" },
                      [
                        _c("p", [_vm._v("Capacidade de pagamento")]),
                        _c("currency-input", {
                          staticClass:
                            "w-full vs-inputx vs-input--input large hasValue",
                          attrs: { disabled: "true" },
                          model: {
                            value: _vm.dadosDoRisco.PaymentCapacity,
                            callback: function($$v) {
                              _vm.$set(_vm.dadosDoRisco, "PaymentCapacity", $$v)
                            },
                            expression: "dadosDoRisco.PaymentCapacity"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      { staticClass: "w-1/4" },
                      [
                        _c("vs-input", {
                          attrs: {
                            label: "Porte",
                            disabled: "true",
                            value: _vm.dadosDoRisco.CompanySize,
                            size: "large"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { staticClass: "w-1/4" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label: "Classe de Risco Financeiro",
                            disabled: "true",
                            value: _vm.dadosDoRisco.OnPointClass,
                            size: "large"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { staticClass: "w-1/4" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label: "Score Final",
                            disabled: "true",
                            value: _vm.dadosDoRisco.FinalScore,
                            size: "large"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { staticClass: "w-1/4" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label: "Risco Score",
                            disabled: "true",
                            value: _vm.dadosDoRisco.RiskClassScore,
                            size: "large"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  [
                    _c("vs-col", { staticClass: "w-1/3" }, [
                      _c("b", [_vm._v("RESTRITIVOS DO TOMADOR:")])
                    ])
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      { staticClass: "w-1/3" },
                      [
                        _c("p", [_vm._v("Debitos Pefins")]),
                        _c("currency-input", {
                          staticClass:
                            "w-full vs-inputx vs-input--input large hasValue",
                          attrs: { disabled: "true" },
                          model: {
                            value: _vm.dadosDoRisco.PefinValue,
                            callback: function($$v) {
                              _vm.$set(_vm.dadosDoRisco, "PefinValue", $$v)
                            },
                            expression: "dadosDoRisco.PefinValue"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { staticClass: "w-1/3" },
                      [
                        _c("p", [_vm._v("Debitos Refins")]),
                        _c("currency-input", {
                          staticClass:
                            "w-full vs-inputx vs-input--input large hasValue",
                          attrs: { disabled: "true" },
                          model: {
                            value: _vm.dadosDoRisco.RefinValue,
                            callback: function($$v) {
                              _vm.$set(_vm.dadosDoRisco, "RefinValue", $$v)
                            },
                            expression: "dadosDoRisco.RefinValue"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { staticClass: "w-1/4" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label: "CCF",
                            disabled: "true",
                            value: _vm.dadosDoRisco.CCF,
                            size: "large"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      { staticClass: "w-1/3" },
                      [
                        _c("p", [_vm._v("Protestos")]),
                        _c("currency-input", {
                          staticClass:
                            "w-full vs-inputx vs-input--input large hasValue",
                          attrs: { disabled: "true" },
                          model: {
                            value: _vm.dadosDoRisco.Protest,
                            callback: function($$v) {
                              _vm.$set(_vm.dadosDoRisco, "Protest", $$v)
                            },
                            expression: "dadosDoRisco.Protest"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { staticClass: "w-1/3" },
                      [
                        _c("p", [_vm._v("Dívidas Vencidas")]),
                        _c("currency-input", {
                          staticClass:
                            "w-full vs-inputx vs-input--input large hasValue",
                          attrs: { disabled: "true" },
                          model: {
                            value: _vm.dadosDoRisco.DebitValue,
                            callback: function($$v) {
                              _vm.$set(_vm.dadosDoRisco, "DebitValue", $$v)
                            },
                            expression: "dadosDoRisco.DebitValue"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { staticClass: "w-1/3" },
                      [
                        _c("p", [_vm._v("Pontos")]),
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: { size: "large", disabled: "true" },
                          model: {
                            value: _vm.dadosDoRisco.Points,
                            callback: function($$v) {
                              _vm.$set(_vm.dadosDoRisco, "Points", $$v)
                            },
                            expression: "dadosDoRisco.Points"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      { staticClass: "w-1/4" },
                      [
                        _c("p", [_vm._v("Data de Consulta Restritivos")]),
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            value: _vm._f("dateFormat")(
                              new Date(
                                (_vm.tomador || {}).LastEvaluationRestrictive
                              ),
                              "DD/MM/YYYY"
                            ),
                            size: "large",
                            disabled: "true"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          this.IsBlocked || this.IsBlockedRestrictive
            ? _c(
                "vs-row",
                [
                  _c(
                    "vs-col",
                    { staticClass: "vx-col pt-5 w-full mt-2" },
                    [
                      _c("label", [_vm._v("Justificativa de bloqueio")]),
                      _c("vs-textarea", {
                        attrs: { disabled: "" },
                        model: {
                          value: this.justificativa,
                          callback: function($$v) {
                            _vm.$set(this, "justificativa", $$v)
                          },
                          expression: "this.justificativa"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.profileHelpers.checkPermission([
            _vm.profileHelpers.menuFuncionalitiesEnum
              .BLOQUEAR_RESTRITIVOS_DO_TOMADOR
          ])
            ? _c(
                "vs-row",
                { staticClass: "mt-6" },
                [
                  _c(
                    "vs-col",
                    { staticClass: "vx-col pt-5 w-full mt-2" },
                    [
                      _c(
                        "vs-checkbox",
                        {
                          on: {
                            change: function($event) {
                              return _vm.blockPolicyHolderRestrictive()
                            }
                          },
                          model: {
                            value: _vm.IsBlockedRestrictive,
                            callback: function($$v) {
                              _vm.IsBlockedRestrictive = $$v
                            },
                            expression: "IsBlockedRestrictive"
                          }
                        },
                        [_vm._v("Bloqueado por restritivos")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "tabContent", attrs: { id: "dadosComplementares" } },
        [
          _c(
            "form-endereco",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.endereco,
                  expression: "endereco"
                }
              ],
              attrs: {
                dadosEndereco: _vm.endereco,
                showOkButton:
                  _vm.profileHelpers.isInsurance() && _vm.podeEditar,
                disabled: !_vm.profileHelpers.isInsurance() || !_vm.podeEditar
              },
              on: { save: _vm.atualizarEndereco }
            },
            [
              _c(
                "vs-row",
                [
                  _c("ValidationProvider", {
                    staticClass: "vs-lg-6 vs-sm-12",
                    attrs: {
                      name: "Telefone",
                      rules: "required",
                      tag: "vs-col"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["(##) ####-####", "(##) #####-####"],
                                  expression:
                                    "['(##) ####-####', '(##) #####-####']"
                                }
                              ],
                              staticClass: "w-full",
                              attrs: {
                                label: "Telefone",
                                disabled:
                                  !_vm.profileHelpers.isInsurance() ||
                                  !_vm.podeEditar,
                                size: "large"
                              },
                              model: {
                                value: _vm.phoneNumber,
                                callback: function($$v) {
                                  _vm.phoneNumber = $$v
                                },
                                expression: "phoneNumber"
                              }
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: errors[0],
                                    expression: "errors[0]"
                                  }
                                ],
                                staticClass: "text-danger text-sm"
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(errors[0]) +
                                    "\n          "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tabContent", attrs: { id: "analiseCredito" } },
        [
          _vm.isTabAnaliseCredito
            ? _c("AnaliseCreditoComponent", {
                attrs: { tomador: _vm.tomadorDetail }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tabContent", attrs: { id: "dadosBancarios" } },
        [
          _c("br"),
          _vm.isTabBank
            ? _c("dadosBancariosComponent", {
                attrs: {
                  mode: "edit",
                  personId: _vm.tomadorDetail.Id,
                  personUniqueId: _vm.tomadorDetail.UniqueId
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: {
            active: _vm.openModalForcarAprovacao,
            title: "Deseja realmente Forçar a aprovação deste tomador?"
          },
          on: {
            "update:active": function($event) {
              _vm.openModalForcarAprovacao = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-default mr-5",
                    on: {
                      click: function($event) {
                        _vm.openModalForcarAprovacao = false
                      }
                    }
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    on: {
                      click: function($event) {
                        return _vm.aprovarTomador(
                          _vm.tomadorDetail.UniqueId,
                          true
                        )
                      }
                    }
                  },
                  [_vm._v("\n          Confirmar\n        ")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: {
            active: _vm.openModalReprocessamento,
            title: "Deseja realmente reprocessar os dados deste tomador?"
          },
          on: {
            "update:active": function($event) {
              _vm.openModalReprocessamento = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-default mr-5",
                    on: {
                      click: function($event) {
                        _vm.openModalReprocessamento = false
                      }
                    }
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    on: {
                      click: function($event) {
                        return _vm.reprocessarTomador(
                          _vm.tomadorDetail.UniqueId,
                          false
                        )
                      }
                    }
                  },
                  [_vm._v("\n          Confirmar\n        ")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: {
            title: "Atualizar comissão do tomador",
            active: _vm.showModalComission
          },
          on: {
            "update:active": function($event) {
              _vm.showModalComission = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "vw-row" },
            [
              _c("vs-input", {
                staticClass: "vx-col w-full mb-3",
                attrs: { label: "Comissão", size: "large" },
                model: {
                  value: _vm.paramModalComission,
                  callback: function($$v) {
                    _vm.paramModalComission = $$v
                  },
                  expression: "paramModalComission"
                }
              }),
              _c(
                "div",
                { staticClass: "vx-col w-full text-right" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "button-default mr-5",
                      on: {
                        click: function($event) {
                          _vm.showModalComission = false
                        }
                      }
                    },
                    [_vm._v("\n          Cancelar\n        ")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "button-primary",
                      attrs: {
                        disabled:
                          _vm.paramModalComission == null ||
                          _vm.paramModalComission == undefined
                      },
                      on: {
                        click: function($event) {
                          return _vm.updateComission()
                        }
                      }
                    },
                    [_vm._v("\n          Salvar\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "modal-opcoes-corretor-nomeado",
          attrs: {
            title: "Opções",
            active: _vm.showModalCorretorOptions,
            width: "200px"
          },
          on: {
            "update:active": function($event) {
              _vm.showModalCorretorOptions = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popup-actions" }, [
            _c(
              "div",
              {
                staticClass: "card-button",
                on: {
                  click: function($event) {
                    _vm.fecharModalOpcoesCoretorNomeado(),
                      _vm.exibirModalAtualizarCoretorNomeado()
                  }
                }
              },
              [_c("b", [_vm._v(" Editar corretor")])]
            ),
            _c(
              "div",
              {
                staticClass: "card-button",
                on: {
                  click: function($event) {
                    _vm.fecharModalOpcoesCoretorNomeado(),
                      _vm.disclamerDesnomear()
                  }
                }
              },
              [_c("b", [_vm._v(" Desnomear corretor")])]
            )
          ])
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "modal-corretor-nomeado",
          attrs: {
            title: "Atualizar corretor nomeado",
            active: _vm.showModalAppointedBroker
          },
          on: {
            "update:active": function($event) {
              _vm.showModalAppointedBroker = $event
            }
          }
        },
        [
          !_vm.mudouCorretorNomeado
            ? _c(
                "div",
                { staticClass: "vw-row" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: ["##.###.###/####-##"],
                        expression: "['##.###.###/####-##']"
                      }
                    ],
                    staticClass: "vx-col w-full mb-3",
                    attrs: {
                      label: "Digite o CNPJ do novo corretor",
                      size: "large"
                    },
                    model: {
                      value: _vm.paramModalAppointedBrokerCNPJ,
                      callback: function($$v) {
                        _vm.paramModalAppointedBrokerCNPJ = $$v
                      },
                      expression: "paramModalAppointedBrokerCNPJ"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full text-right" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "button-default mr-5",
                          on: {
                            click: function($event) {
                              _vm.showModalAppointedBroker = false
                              _vm.showModalCorretorOptions = true
                              _vm.paramModalAppointedBrokerCNPJ = ""
                            }
                          }
                        },
                        [_vm._v("\n          Cancelar\n        ")]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "button-primary",
                          attrs: { disabled: _vm.disableSeachBrokerCNPJ },
                          on: {
                            click: function($event) {
                              return _vm.buscarCorretorPorCNPJ(
                                _vm.paramModalAppointedBrokerCNPJ
                              )
                            }
                          }
                        },
                        [_vm._v("\n          Buscar\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c("div", { staticClass: "vw-row" }, [
                _c("div", { staticClass: "vx-col w-full mt-3 mb-5" }, [
                  _c(
                    "div",
                    {
                      staticClass: "vx-col w-full modal-corretor-nomeado-infos"
                    },
                    [
                      _c(
                        "h6",
                        {
                          staticClass: "modal-corretor-nomeado-infos-conteudo"
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.novoCorretorNomeado.NameSusep) +
                              "\n          "
                          )
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "modal-corretor-nomeado-infos-conteudo"
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.adicionarMascaraCNPJ(
                                  _vm.novoCorretorNomeado.CpfCnpj
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "modal-corretor-nomeado-infos-conteudo"
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.novoCorretorNomeado.LocationAddress) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col w-full mt-5 modal-corretor-nomeado-validade"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "modal-corretor-nomeado-validade-titulo"
                        },
                        [
                          _vm._v("Data de validade da nomeação "),
                          _c("strong", [_vm._v("(opcional)")])
                        ]
                      ),
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "Nova data",
                          format: "dd/MM/yyyy",
                          "value-format": "yyyy/MM/dd"
                        },
                        model: {
                          value: _vm.paramNomeationExpireDate,
                          callback: function($$v) {
                            _vm.paramNomeationExpireDate = $$v
                          },
                          expression: "paramNomeationExpireDate"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full text-right" },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "button-default mr-5",
                        on: {
                          click: function($event) {
                            return _vm.limparBuscaCorretorPorCNPJ()
                          }
                        }
                      },
                      [_vm._v("\n          Limpar\n        ")]
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "button-primary",
                        attrs: { disabled: _vm.disableSaveNewAppointedBroker },
                        on: {
                          click: function($event) {
                            return _vm.atualizarCoretorNomeado()
                          }
                        }
                      },
                      [_vm._v("\n          Salvar\n        ")]
                    )
                  ],
                  1
                )
              ])
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: {
            title: "Atualizar Data de validade cadastral",
            active: _vm.showModalDate
          },
          on: {
            "update:active": function($event) {
              _vm.showModalDate = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "vw-row" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "Nova data",
                  format: "dd/MM/yyyy",
                  "value-format": "yyyy/MM/dd"
                },
                model: {
                  value: _vm.paramModalDate,
                  callback: function($$v) {
                    _vm.paramModalDate = $$v
                  },
                  expression: "paramModalDate"
                }
              }),
              _c("br"),
              _c("br"),
              _c(
                "div",
                { staticClass: "vx-col w-full text-right" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "button-default mr-5",
                      on: {
                        click: function($event) {
                          _vm.showModalDate = false
                        }
                      }
                    },
                    [_vm._v("\n          Cancelar\n        ")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "button-primary",
                      attrs: {
                        disabled:
                          _vm.paramModalDate == null ||
                          _vm.paramModalDate == undefined
                      },
                      on: {
                        click: function($event) {
                          return _vm.updateDate()
                        }
                      }
                    },
                    [_vm._v("\n          Salvar\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: {
            title: "Atualizar nome do tomador`",
            active: _vm.showModalUpdate
          },
          on: {
            "update:active": function($event) {
              _vm.showModalUpdate = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "vw-row" },
            [
              _c("vs-input", {
                staticClass: "vx-col w-full mb-3",
                attrs: { label: "Nome", size: "large" },
                model: {
                  value: _vm.paramModalUpdate,
                  callback: function($$v) {
                    _vm.paramModalUpdate = $$v
                  },
                  expression: "paramModalUpdate"
                }
              }),
              _c(
                "div",
                { staticClass: "vx-col w-full text-right" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "button-default mr-5",
                      on: {
                        click: function($event) {
                          _vm.showModalUpdate = false
                        }
                      }
                    },
                    [_vm._v("\n          Cancelar\n        ")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "button-primary",
                      attrs: {
                        disabled:
                          _vm.paramModalUpdate == null ||
                          _vm.paramModalUpdate == undefined
                      },
                      on: {
                        click: function($event) {
                          return _vm.updateName()
                        }
                      }
                    },
                    [_vm._v("\n          Salvar\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: { title: "ConsultarWS", active: _vm.consultarWSDialog },
          on: {
            "update:active": function($event) {
              _vm.consultarWSDialog = $event
            }
          }
        },
        [
          _c("div", [
            _c("div", { staticClass: "my-5" }, [
              _vm._v("\n        Deseja consultar no receita WS?\n      ")
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-default mr-5",
                    on: {
                      click: function($event) {
                        _vm.consultarWSDialog = !_vm.consultarWSDialog
                      }
                    }
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    on: {
                      click: function($event) {
                        return _vm.fecthConsultarWS()
                      }
                    }
                  },
                  [_vm._v("\n          Confirmar\n        ")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "text-center",
          attrs: { title: "Alerta!", active: _vm.consultarWSDialogMsg },
          on: {
            "update:active": function($event) {
              _vm.consultarWSDialogMsg = $event
            }
          }
        },
        [
          _c("div", { staticClass: "text-center vw-row" }, [
            _vm._v(
              "\n      O processo de consulta está em andamento. Isso pode levar aproximadamente 5 minutos. Os resultados só serão atualizados na tela após uma nova proposta ser utilizada\n    "
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }