var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-5 limites_taxas" },
    [
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            { staticClass: "w-1/2" },
            [
              _c("p", [_vm._v("Capacidade de Pagamento")]),
              _c("currency-input", {
                staticClass: "w-full vs-inputx vs-input--input large hasValue",
                model: {
                  value: _vm.tomadorDetail.PaymentCapacity,
                  callback: function($$v) {
                    _vm.$set(_vm.tomadorDetail, "PaymentCapacity", $$v)
                  },
                  expression: "tomadorDetail.PaymentCapacity"
                }
              })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-1/2" },
            [
              _c("p", [_vm._v("Faturamento Real")]),
              _c("currency-input", {
                staticClass: "w-full vs-inputx vs-input--input large hasValue",
                model: {
                  value: _vm.tomadorDetail.RealRevenue,
                  callback: function($$v) {
                    _vm.$set(_vm.tomadorDetail, "RealRevenue", $$v)
                  },
                  expression: "tomadorDetail.RealRevenue"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            { staticClass: "w-1/2" },
            [
              _c("p", [_vm._v("Patrimonio Liquido")]),
              _c("currency-input", {
                staticClass: "w-full vs-inputx vs-input--input large hasValue",
                model: {
                  value: _vm.tomadorDetail.NetWorth,
                  callback: function($$v) {
                    _vm.$set(_vm.tomadorDetail, "NetWorth", $$v)
                  },
                  expression: "tomadorDetail.NetWorth"
                }
              })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-1/2" },
            [
              _c("p", [_vm._v("Porte")]),
              _c("vs-input", {
                attrs: { size: "large" },
                model: {
                  value: _vm.tomadorDetail.CompanySize,
                  callback: function($$v) {
                    _vm.$set(_vm.tomadorDetail, "CompanySize", $$v)
                  },
                  expression: "tomadorDetail.CompanySize"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            { staticClass: "w-1/2" },
            [
              _c("p", [_vm._v("Limite Revisado")]),
              _c("currency-input", {
                staticClass: "w-full vs-inputx vs-input--input large hasValue",
                model: {
                  value: _vm.tomadorDetail.RevisedLimit,
                  callback: function($$v) {
                    _vm.$set(_vm.tomadorDetail, "RevisedLimit", $$v)
                  },
                  expression: "tomadorDetail.RevisedLimit"
                }
              })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-1/2" },
            [
              _c("p", [_vm._v("Data da Revisão")]),
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  format: "dd/MM/yyyy",
                  "default-value": _vm.today
                },
                model: {
                  value: _vm.tomadorDetail.RevisionDate,
                  callback: function($$v) {
                    _vm.$set(_vm.tomadorDetail, "RevisionDate", $$v)
                  },
                  expression: "tomadorDetail.RevisionDate"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            { staticClass: "w-full" },
            [
              _c("vs-textarea", {
                attrs: { label: "observações", height: "15rem" },
                model: {
                  value: _vm.tomadorDetail.Observations,
                  callback: function($$v) {
                    _vm.$set(_vm.tomadorDetail, "Observations", $$v)
                  },
                  expression: "tomadorDetail.Observations"
                }
              })
            ],
            1
          ),
          _c(
            "vs-button",
            {
              staticClass: "button-primary",
              on: {
                click: function($event) {
                  return _vm.analiseCredito()
                }
              }
            },
            [_vm._v("\n      Salvar\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }