var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loadCompleted,
          expression: "loadCompleted"
        }
      ],
      staticClass: "px-4 pt-5 pb-4 ccg"
    },
    [
      _vm.ccg
        ? [
            _vm.ccg.TemDocumentoCustomizado
              ? _c(
                  "div",
                  { staticClass: "upload_ccg ccg-alert" },
                  [
                    _c(
                      "el-alert",
                      {
                        staticClass: "ccg-alert",
                        attrs: { type: "warning", closable: false }
                      },
                      [
                        _vm._v(
                          "\n        Este tomador possui template customizado no CCG.\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.uploadModal
              ? _c("base64Upload", {
                  attrs: {
                    modalUpload: "CCG",
                    documentoName: "CCG",
                    uniqueId: _vm.tomador.UniqueId
                  },
                  on: {
                    callBackUpload: _vm.callbackUploadManual,
                    closeModal: _vm.fecharPopupUploadManual
                  }
                })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "ccg-alert" },
              [
                _c(
                  "el-alert",
                  {
                    staticClass: "ccg-alert",
                    attrs: { type: _vm.tipoAvisoStatus, closable: false }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.ccg.Contrato.DescricaoStatus) +
                        "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _c("VisualizarAlteracoes", {
              staticClass: "view-changes",
              attrs: { contrato: _vm.ccg.Contrato }
            }),
            _vm.tomador.Id > 0 &&
            (_vm.representantesLegais || []).length == 0 &&
            _vm.deveExibirAdicionarParteQuandoParteVazia(1)
              ? _c("div", { staticClass: "representatives-alerts" }, [
                  _c(
                    "h4",
                    {
                      staticClass:
                        "representatives-alerts-title font-bold text-primary"
                    },
                    [
                      _vm._v(
                        "\n        Representantes legais do tomador\n      "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "ccg-alert representatives-alerts-content" },
                    [
                      _c(
                        "el-alert",
                        { attrs: { type: "warning", closable: false } },
                        [
                          _vm._v(
                            '\n          Esse tomador não possui representantes legais. Para adicionar um\n          representante, clique no botão de "Adicionar representante".\n        '
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "representatives-alerts-add" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "representatives-alerts-add-btn",
                          attrs: {
                            type: "primary",
                            disabled: _vm.ccg.Contrato.StatusId !== 1
                          },
                          on: {
                            click: function($event) {
                              return _vm.exibirAdcPopupQuandoRepFiadorVazio(1)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-user" }),
                          _vm._v(
                            "\n          Adicionar representante\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _c("TabelaPartes", {
                  attrs: {
                    tipoParteId: 1,
                    partes: _vm.representantesLegais,
                    permissoes: _vm.permissoes,
                    contratoEspecificacoes: _vm.ccg.Contrato
                  },
                  on: {
                    adicionarParteSelecionado: _vm.exibirAdcPopupRepFiadorPF,
                    edicaoDadosSelecionado: _vm.exibirEdicaoDados,
                    reenviarEmailSelecionado:
                      _vm.lidarComReenviarEmailIndividual,
                    copiarLinkAssinaturaSelecionado:
                      _vm.lidarComCopiarLinkAssinatura,
                    excluirRepresentanteSelecionado: _vm.exibirExcluirPartePopup
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n        Representantes legais do tomador\n      "
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "alert",
                        fn: function() {
                          return [_vm._v("\n        Erro\n      ")]
                        },
                        proxy: true
                      },
                      {
                        key: "button",
                        fn: function() {
                          return [
                            _vm._v("\n        Adicionar representante\n      ")
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2342141383
                  )
                }),
            _vm.tomador.Id > 0 &&
            (_vm.fiadoresPF || []).length === 0 &&
            _vm.deveExibirAdicionarParteQuandoParteVazia(3)
              ? _c("div", { staticClass: "representatives-alerts" }, [
                  _c(
                    "h4",
                    {
                      staticClass:
                        "representatives-alerts-title font-bold text-primary"
                    },
                    [
                      _vm._v(
                        "\n        Fiadores do tomador (Pessoas Físicas)\n      "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "ccg-alert representatives-alerts-content" },
                    [
                      _c(
                        "el-alert",
                        { attrs: { type: "warning", closable: false } },
                        [
                          _vm._v(
                            '\n          Esse tomador não possui fiadores PF. Para adicionar um fiador,\n          clique no botão de "Adicionar Fiador PF".\n        '
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "representatives-alerts-add" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "representatives-alerts-add-btn",
                          attrs: {
                            type: "primary",
                            disabled: _vm.ccg.Contrato.StatusId !== 1
                          },
                          on: {
                            click: function($event) {
                              return _vm.exibirAdcPopupQuandoRepFiadorVazio(3)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-user" }),
                          _vm._v("\n          Adicionar fiador PF\n        ")
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _c("TabelaPartes", {
                  attrs: {
                    tipoParteId: 3,
                    partes: _vm.fiadoresPF,
                    permissoes: _vm.permissoes,
                    contratoEspecificacoes: _vm.ccg.Contrato,
                    conjugeHabilitado: _vm.ccg.UsaConjuge
                  },
                  on: {
                    adicionarParteSelecionado: _vm.exibirAdcPopupRepFiadorPF,
                    edicaoDadosSelecionado: _vm.exibirEdicaoDados,
                    adicionarConjugeSelecionado: _vm.exibirAdcPopupConjuge,
                    reenviarEmailSelecionado:
                      _vm.lidarComReenviarEmailIndividual,
                    copiarLinkAssinaturaSelecionado:
                      _vm.lidarComCopiarLinkAssinatura,
                    excluirRepresentanteSelecionado: _vm.exibirExcluirPartePopup
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n        Fiadores do tomador (Pessoas Físicas)\n      "
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "alert",
                        fn: function() {
                          return [_vm._v("\n        Erro\n      ")]
                        },
                        proxy: true
                      },
                      {
                        key: "button",
                        fn: function() {
                          return [_vm._v("\n        Adicionar fiador\n      ")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3790382014
                  )
                }),
            _vm.tomador.Id > 0 &&
            (_vm.fiadoresPJ || []).length == 0 &&
            _vm.deveExibirAdicionarParteQuandoParteVazia(2)
              ? _c("div", { staticClass: "representatives-alerts" }, [
                  _c(
                    "h4",
                    {
                      staticClass:
                        "representatives-alerts-title font-bold text-primary"
                    },
                    [
                      _vm._v(
                        "\n        Fiadores do tomador (Pessoas Jurídicas)\n      "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "ccg-alert representatives-alerts-content" },
                    [
                      _c(
                        "el-alert",
                        { attrs: { type: "warning", closable: false } },
                        [
                          _vm._v(
                            '\n          Esse tomador não possui fiadores PJ. Para adicionar um fiador,\n          clique no botão de "Adicionar Fiador PJ".\n        '
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "representatives-alerts-add" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "representatives-alerts-add-btn",
                          attrs: {
                            type: "primary",
                            disabled: _vm.ccg.Contrato.StatusId !== 1
                          },
                          on: {
                            click: function($event) {
                              return _vm.exibirAdcPopupQuandoRepFiadorVazio(2)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-user" }),
                          _vm._v("\n          Adicionar fiador PJ\n        ")
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _c("TabelaPartes", {
                  attrs: {
                    tipoParteId: 2,
                    partes: _vm.fiadoresPJ,
                    permissoes: _vm.permissoes,
                    contratoEspecificacoes: _vm.ccg.Contrato
                  },
                  on: {
                    adicionarParteSelecionado: _vm.exibirAdcPopupFiadorPJ,
                    edicaoDadosSelecionado: _vm.exibirEdicaoDados,
                    reenviarEmailSelecionado:
                      _vm.lidarComReenviarEmailIndividual,
                    copiarLinkAssinaturaSelecionado:
                      _vm.lidarComCopiarLinkAssinatura,
                    excluirRepresentanteSelecionado: _vm.exibirExcluirPartePopup
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n        Fiadores do tomador (Pessoas Jurídicas)\n      "
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "alert",
                        fn: function() {
                          return [
                            _vm._v(
                              '\n        Esse tomador não possui fiadores PJ. Para adicionar um fiador, clique\n        no botão de "Adicionar Fiador PJ".\n      '
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "button",
                        fn: function() {
                          return [_vm._v("\n        Adicionar fiador\n      ")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2289113328
                  )
                }),
            _vm.ccg.UsaTestemunha
              ? _c(
                  "div",
                  [
                    _vm.tomador.Id > 0 &&
                    (_vm.testemunhas || []).length == 0 &&
                    _vm.deveExibirAdicionarParteQuandoParteVazia(4)
                      ? _c("div", { staticClass: "representatives-alerts" }, [
                          _c(
                            "h4",
                            {
                              staticClass:
                                "representatives-alerts-title font-bold text-primary"
                            },
                            [_vm._v("\n          Testemunhas\n        ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "ccg-alert representatives-alerts-content"
                            },
                            [
                              _c(
                                "el-alert",
                                { attrs: { type: "warning", closable: false } },
                                [
                                  _vm._v(
                                    '\n            Esse tomador não possui testemunhas. Para adicionar uma\n            testemunha, clique no botão de "Adicionar testemunha".\n          '
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "representatives-alerts-add" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "representatives-alerts-add-btn",
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.ccg.Contrato.StatusId !== 1
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.exibirAdcPopupQuandoRepFiadorVazio(
                                        4
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "el-icon-user" }),
                                  _vm._v(
                                    "\n            Adicionar testemunha\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _c("TabelaPartes", {
                          attrs: {
                            tipoParteId: 4,
                            partes: _vm.testemunhas,
                            permissoes: _vm.permissoes,
                            contratoEspecificacoes: _vm.ccg.Contrato
                          },
                          on: {
                            adicionarParteSelecionado:
                              _vm.exibirAdcPopupRepFiadorPF,
                            edicaoDadosSelecionado: _vm.exibirEdicaoDados,
                            reenviarEmailSelecionado:
                              _vm.lidarComReenviarEmailIndividual,
                            copiarLinkAssinaturaSelecionado:
                              _vm.lidarComCopiarLinkAssinatura,
                            excluirRepresentanteSelecionado:
                              _vm.exibirExcluirPartePopup
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function() {
                                  return [
                                    _vm._v("\n          Testemunhas\n        ")
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "alert",
                                fn: function() {
                                  return [_vm._v("\n          Erro\n        ")]
                                },
                                proxy: true
                              },
                              {
                                key: "button",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      "\n          Adicionar testemunha\n        "
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            749381879
                          )
                        })
                  ],
                  1
                )
              : _vm._e(),
            this.ccg.RegraBypass
              ? _c("div", { staticClass: "representatives-alerts" }, [
                  _c(
                    "h4",
                    {
                      staticClass:
                        "representatives-alerts-title font-bold text-primary"
                    },
                    [_vm._v("\n        Assinatura da seguradora\n      ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "ccg-alert mt-5" },
                    [
                      _c(
                        "el-alert",
                        {
                          staticClass: "ccg-alert",
                          attrs: { type: _vm.tipoAvisoStatus, closable: false }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                this.ccg.MsgBypass ||
                                  "Esta seguradora adota uma política de bypass, o que significa que a regularização do CCG é concluída assim que 100% das partes do Tomador e Fiadores assinam na plataforma. Nesse caso, não é necessário a apresentação dos representantes da seguradora."
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _c("TabelaPartes", {
                  attrs: {
                    tipoParteId: 6,
                    partes: _vm.Seguradoras,
                    contratoEspecificacoes: _vm.ccg.Contrato
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _vm._v("\n        Assinatura da seguradora\n      ")
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "alert",
                        fn: function() {
                          return [_vm._v("\n        Erro\n      ")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3251853581
                  )
                }),
            _c(
              "el-dialog",
              {
                staticClass: "registered-part-popup",
                attrs: {
                  visible: _vm.popupAlertaParteCadastrada,
                  width: "32%",
                  "before-close": _vm.fecharPopupAlertaParteCadastrada
                },
                on: {
                  "update:visible": function($event) {
                    _vm.popupAlertaParteCadastrada = $event
                  }
                }
              },
              [
                _c("div", { staticClass: "registered-part-popup-content" }, [
                  _c(
                    "div",
                    { staticClass: "registered-part-popup-content-header" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/icons/ccg-icons/add-icon-ccg.svg"),
                          alt: "Ícone de link individual de assinatura",
                          width: "24",
                          height: "24"
                        }
                      }),
                      _c("h3", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              "Adicionar " +
                                _vm.representanteOuFiador.TipoParteName.toLowerCase()
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "registered-part-popup-content-body" },
                    [
                      _c(
                        "el-alert",
                        {
                          staticClass:
                            "registered-part-popup-content-body-alert",
                          attrs: {
                            type: "warning",
                            center: "",
                            closable: false
                          }
                        },
                        [
                          _vm._v(
                            "\n            CPF já está cadastrado para este tomador.\n            "
                          ),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            "\n            Caso os novos dados divergirem, "
                          ),
                          _c("br"),
                          _vm._v(
                            "essa ação irá alterar os\n            dados já cadastrados!\n            "
                          ),
                          _c("br"),
                          _c("br"),
                          _vm._v("\n            Deseja continuar?")
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer registered-part-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.lidarComVariacoesAdicionarParte }
                      },
                      [_vm._v("Sim, continuar")]
                    ),
                    _c(
                      "el-button",
                      { on: { click: _vm.fecharPopupAlertaParteCadastrada } },
                      [_vm._v("Cancelar")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "add-edit-rep-guarantor-pf-popup",
                attrs: {
                  width: "28%",
                  visible: _vm.popupAdcEditRepresentanteFiadorPF
                },
                on: {
                  "update:visible": function($event) {
                    _vm.popupAdcEditRepresentanteFiadorPF = $event
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "add-edit-rep-guarantor-pf-popup-content" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "add-edit-rep-guarantor-pf-popup-content-header"
                      },
                      [
                        !_vm.editarRepresentanteFiadorPF
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/ccg-icons/add-icon-ccg.svg"),
                                alt: "Ícone de adição",
                                width: "24",
                                height: "24"
                              }
                            })
                          : _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/ccg-icons/edit-icon-ccg.svg"),
                                alt: "Ícone de edição",
                                width: "24",
                                height: "24"
                              }
                            }),
                        _c("h3", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.editarRepresentanteFiadorPF
                                  ? "Editar " +
                                      _vm.representanteOuFiador.TipoParteName.toLowerCase()
                                  : "Adicionar " +
                                      _vm.representanteOuFiador.TipoParteName.toLowerCase()
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "add-edit-rep-guarantor-pf-popup-content-body"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "add-edit-rep-guarantor-pf-popup-content-body-input"
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "CPF do representante",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "representative-cpf" }
                                          },
                                          [_vm._v("CPF DO REPRESENTANTE")]
                                        ),
                                        _vm.editarRepresentanteFiadorPF
                                          ? _c("el-input", {
                                              staticClass: "w-full",
                                              attrs: {
                                                id: "representative-cpf",
                                                size: "large",
                                                label: "CPF do Representante",
                                                disabled: _vm.editarRepresentanteFiadorPF
                                                  ? true
                                                  : false
                                              },
                                              model: {
                                                value:
                                                  _vm.representanteOuFiador
                                                    .CPFMask,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.representanteOuFiador,
                                                    "CPFMask",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "representanteOuFiador.CPFMask "
                                              }
                                            })
                                          : _c("el-input", {
                                              staticClass: "w-full",
                                              attrs: {
                                                id: "representative-cpf",
                                                size: "large",
                                                label: "CPF do Representante",
                                                disabled: _vm.editarRepresentanteFiadorPF
                                                  ? true
                                                  : false
                                              },
                                              model: {
                                                value:
                                                  _vm.representanteOuFiador.CPF,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.representanteOuFiador,
                                                    "CPF",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "representanteOuFiador.CPF"
                                              }
                                            }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ],
                                            staticClass: "text-danger pl-3"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(errors[0]) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                246086223
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "add-edit-rep-guarantor-pf-popup-content-body-input"
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "nome do representante",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "representative-name"
                                            }
                                          },
                                          [_vm._v("NOME DO REPRESENTANTE")]
                                        ),
                                        _c("el-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            id: "representative-name",
                                            size: "large",
                                            label: "Nome do Representante"
                                          },
                                          model: {
                                            value:
                                              _vm.representanteOuFiador.Nome,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.representanteOuFiador,
                                                "Nome",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "representanteOuFiador.Nome"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ],
                                            staticClass: "text-danger pl-3"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(errors[0]) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                793315725
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "add-edit-rep-guarantor-pf-popup-content-body-input"
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "e-mail do representante",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "representative-email"
                                            }
                                          },
                                          [_vm._v("E-MAIL DO REPRESENTANTE")]
                                        ),
                                        _c("el-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            size: "large",
                                            label: "E-mail do Representante"
                                          },
                                          model: {
                                            value:
                                              _vm.representanteOuFiador.Email,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.representanteOuFiador,
                                                "Email",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "representanteOuFiador.Email"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ],
                                            staticClass: "text-danger pl-3"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(errors[0]) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3115451649
                              )
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "dialog-footer add-edit-rep-guarantor-pf-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass:
                          "add-edit-rep-guarantor-pf-popup-footer-btn",
                        attrs: {
                          type: "primary",
                          disabled: _vm.desabilitarAdcEdtRepresentanteFiadorPF
                        },
                        on: {
                          click: function($event) {
                            return _vm.deveEditarOuAdicionarParte()
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.editarRepresentanteFiadorPF
                              ? "Salvar " +
                                  _vm.representanteOuFiador.TipoParteName.toLowerCase()
                              : "Adicionar " +
                                  _vm.representanteOuFiador.TipoParteName.toLowerCase()
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "add-edit-rep-guarantor-pf-feedback-popup",
                attrs: {
                  width: "28%",
                  visible: _vm.popupFeedbackAdcEdtRepEFiadorPF,
                  "before-close": _vm.fecharAdcEdtFeedbackPopupRepFiadorPF
                },
                on: {
                  "update:visible": function($event) {
                    _vm.popupFeedbackAdcEdtRepEFiadorPF = $event
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "add-edit-rep-guarantor-pf-feedback-popup-content"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "add-edit-rep-guarantor-pf-feedback-popup-content-header"
                      },
                      [
                        !_vm.editarRepresentanteFiadorPF
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/ccg-icons/add-icon-ccg.svg"),
                                alt: "Ícone de adição",
                                width: "24",
                                height: "24"
                              }
                            })
                          : _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/ccg-icons/edit-icon-ccg.svg"),
                                alt: "Ícone de edição",
                                width: "24",
                                height: "24"
                              }
                            }),
                        _c("h3", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.editarRepresentanteFiadorPF
                                  ? "Editar " +
                                      _vm.representanteOuFiador.TipoParteName.toLowerCase()
                                  : "Adicionar " +
                                      _vm.representanteOuFiador.TipoParteName.toLowerCase()
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "add-edit-rep-guarantor-pf-feedback-popup-content-body"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "add-edit-rep-guarantor-pf-feedback-popup-content-body-title"
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/ccg-icons/success-icon.svg"),
                                alt: "Ícone de sucesso para edição de dados",
                                width: "48",
                                height: "48"
                              }
                            }),
                            _c("p", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.editarRepresentanteFiadorPF
                                      ? _vm.representanteOuFiador
                                          .TipoParteName + " Editado(a)"
                                      : _vm.representanteOuFiador
                                          .TipoParteName + " Adicionado(a)"
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "add-edit-rep-guarantor-pf-feedback-popup-content-body-infos"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-list"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-divider"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-title"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              "CPF DO(A) " +
                                                _vm.representanteOuFiador.TipoParteName.toUpperCase()
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-content"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.representanteOuFiador.CPF
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-divider"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-title"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              "NOME DO(A) " +
                                                _vm.representanteOuFiador.TipoParteName.toUpperCase()
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-content"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.representanteOuFiador.Nome
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-divider"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-title"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              "E-MAIL DO(A) " +
                                                _vm.representanteOuFiador.TipoParteName.toUpperCase()
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-edit-rep-guarantor-pf-feedback-popup-content-body-infos-content"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.representanteOuFiador.Email
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "dialog-footer add-edit-rep-guarantor-pf-feedback-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.fecharAdcEdtFeedbackPopupRepFiadorPF }
                      },
                      [_vm._v("Fechar\n        ")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "add-edit-spouse-pf-popup",
                attrs: { width: "28%", visible: _vm.popupAdcEdtConjuge },
                on: {
                  "update:visible": function($event) {
                    _vm.popupAdcEdtConjuge = $event
                  }
                }
              },
              [
                _c("div", { staticClass: "add-edit-spouse-pf-popup-content" }, [
                  _c(
                    "div",
                    { staticClass: "add-edit-spouse-pf-popup-content-header" },
                    [
                      !_vm.editarConjuge
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/icons/ccg-icons/spouse-icon-ccg.svg"),
                              alt: "Ícone de adição",
                              width: "24",
                              height: "24"
                            }
                          })
                        : _c("img", {
                            attrs: {
                              src: require("@/assets/images/icons/ccg-icons/edit-icon-ccg.svg"),
                              alt: "Ícone de edição",
                              width: "24",
                              height: "24"
                            }
                          }),
                      _c("h3", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.editarConjuge
                                ? "Editar cônjuge"
                                : "Adicionar cônjuge"
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "add-edit-spouse-pf-popup-content-body" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "add-edit-spouse-pf-popup-content-body-input"
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "CPF do cônjuge",
                              rules: "required|cpf"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "label",
                                        { attrs: { for: "spouse-cpf" } },
                                        [_vm._v("CPF DO CÔNJUGE")]
                                      ),
                                      _c("el-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: ["###.###.###-##"],
                                            expression: "['###.###.###-##']"
                                          }
                                        ],
                                        staticClass: "w-full",
                                        attrs: {
                                          id: "spouse-cpf",
                                          size: "large",
                                          label: "CPF do cônjuge"
                                        },
                                        model: {
                                          value: _vm.representanteOuFiador.CPF,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.representanteOuFiador,
                                              "CPF",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "representanteOuFiador.CPF"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ],
                                          staticClass: "text-danger pl-3"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(errors[0]) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1535581064
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "add-edit-spouse-pf-popup-content-body-input"
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "nome do cônjuge",
                              rules: "required"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "label",
                                        { attrs: { for: "spouse-name" } },
                                        [_vm._v("NOME DO CÔNJUGE")]
                                      ),
                                      _c("el-input", {
                                        staticClass: "w-full",
                                        attrs: {
                                          id: "spouse-name",
                                          size: "large",
                                          label: "Nome do cônjuge"
                                        },
                                        model: {
                                          value: _vm.representanteOuFiador.Nome,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.representanteOuFiador,
                                              "Nome",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "representanteOuFiador.Nome"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ],
                                          staticClass: "text-danger pl-3"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(errors[0]) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1995211053
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "add-edit-spouse-pf-popup-content-body-input"
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "e-mail do cônjuge",
                              rules: "required"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "label",
                                        { attrs: { for: "spouse-email" } },
                                        [_vm._v("E-MAIL DO CÔNJUGE")]
                                      ),
                                      _c("el-input", {
                                        staticClass: "w-full",
                                        attrs: {
                                          id: "spouse-email",
                                          size: "large",
                                          label: "E-mail do cônjuge"
                                        },
                                        model: {
                                          value:
                                            _vm.representanteOuFiador.Email,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.representanteOuFiador,
                                              "Email",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "representanteOuFiador.Email"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ],
                                          staticClass: "text-danger pl-3"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(errors[0]) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1721664072
                            )
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]),
                _c(
                  "span",
                  {
                    staticClass:
                      "dialog-footer add-edit-spouse-pf-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "add-edit-spouse-pf-popup-footer-btn",
                        attrs: {
                          type: "primary",
                          disabled: _vm.desabilitarAdicionarConjuge
                        },
                        on: { click: _vm.verficarSeParteJaCadastrada }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.editarConjuge
                              ? "Salvar cônjuge"
                              : "Adicionar cônjuge"
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "add-edit-spouse-pf-feedback-popup",
                attrs: {
                  width: "28%",
                  visible: _vm.popupFeedbackAdcEdtConjuge,
                  "before-close": _vm.fecharAdcEdtFeedbackPopupRepConjuge
                },
                on: {
                  "update:visible": function($event) {
                    _vm.popupFeedbackAdcEdtConjuge = $event
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "add-edit-spouse-pf-feedback-popup-content" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "add-edit-spouse-pf-feedback-popup-content-header"
                      },
                      [
                        !_vm.editarConjuge
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/ccg-icons/spouse-icon-ccg.svg"),
                                alt: "Ícone de adição",
                                width: "24",
                                height: "24"
                              }
                            })
                          : _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/ccg-icons/edit-icon-ccg.svg"),
                                alt: "Ícone de edição",
                                width: "24",
                                height: "24"
                              }
                            }),
                        _c("h3", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.editarConjuge
                                  ? "Editar cônjuge"
                                  : "Adicionar cônjuge"
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "add-edit-spouse-pf-feedback-popup-content-body"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "add-edit-spouse-pf-feedback-popup-content-body-title"
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/ccg-icons/success-icon.svg"),
                                alt: "Ícone de sucesso para edição de dados",
                                width: "48",
                                height: "48"
                              }
                            }),
                            _c("p", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.editarConjuge
                                      ? "Cônjuge editado"
                                      : "Cônjuge adicionado"
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "add-edit-spouse-pf-feedback-popup-content-body-infos"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "add-edit-spouse-pf-feedback-popup-content-body-infos-list"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "add-edit-spouse-pf-feedback-popup-content-body-infos-divider"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-edit-spouse-pf-feedback-popup-content-body-infos-title"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  CPF DO CÔNJUGE\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-edit-spouse-pf-feedback-popup-content-body-infos-content"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.representanteOuFiador.CPF
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "add-edit-spouse-pf-feedback-popup-content-body-infos-divider"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-edit-spouse-pf-feedback-popup-content-body-infos-title"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  NOME DO CÔNJUGE\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-edit-spouse-pf-feedback-popup-content-body-infos-content"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.representanteOuFiador.Nome
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "add-edit-spouse-pf-feedback-popup-content-body-infos-divider"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-edit-spouse-pf-feedback-popup-content-body-infos-title"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  E-MAIL DO CÔNJUGE\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-edit-spouse-pf-feedback-popup-content-body-infos-content"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.representanteOuFiador.Email
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "dialog-footer add-edit-spouse-pf-feedback-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.fecharAdcEdtFeedbackPopupRepConjuge }
                      },
                      [_vm._v("Fechar\n        ")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "add-guarantor-pj-popup",
                attrs: { width: "28%", visible: _vm.popupAdcFiadorPJ },
                on: {
                  "update:visible": function($event) {
                    _vm.popupAdcFiadorPJ = $event
                  }
                }
              },
              [
                _c("div", { staticClass: "add-guarantor-pj-popup-content" }, [
                  _c(
                    "div",
                    { staticClass: "add-guarantor-pj-popup-content-header" },
                    [
                      !_vm.editarFiadorPJ
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/icons/ccg-icons/add-icon-ccg.svg"),
                              alt: "Ícone de adição",
                              width: "24",
                              height: "24"
                            }
                          })
                        : _c("img", {
                            attrs: {
                              src: require("@/assets/images/icons/ccg-icons/edit-icon-ccg.svg"),
                              alt: "Ícone de edição",
                              width: "24",
                              height: "24"
                            }
                          }),
                      _c("h3", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.editarFiadorPJ
                                ? "Editar " +
                                    _vm.representanteOuFiador.TipoParteName.toLowerCase()
                                : "Adicionar " +
                                    _vm.representanteOuFiador.TipoParteName.toLowerCase()
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "add-guarantor-pj-popup-content-body" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "add-guarantor-pj-popup-content-body-input"
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "CNPJ do fiador",
                              rules: "required|cnpj"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "label",
                                        { attrs: { for: "guarantor-cnpj" } },
                                        [_vm._v("CNPJ DO FIADOR")]
                                      ),
                                      _c("el-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: ["##.###.###/####-##"],
                                            expression: "['##.###.###/####-##']"
                                          }
                                        ],
                                        staticClass: "w-full",
                                        attrs: {
                                          id: "guarantor-cnpj",
                                          size: "large",
                                          label: "CNPJ do fiador"
                                        },
                                        model: {
                                          value: _vm.representanteOuFiador.CNPJ,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.representanteOuFiador,
                                              "CNPJ",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "representanteOuFiador.CNPJ"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ],
                                          staticClass: "text-danger pl-3"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(errors[0]) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3743805258
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "add-guarantor-pj-popup-content-body-input"
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "razao social do fiador",
                              rules: "required"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "guarantor-corporate-name"
                                          }
                                        },
                                        [_vm._v("RAZÃO SOCIAL DO FIADOR")]
                                      ),
                                      _c("el-input", {
                                        staticClass: "w-full",
                                        attrs: {
                                          id: "guarantor-corporate-name",
                                          size: "large",
                                          label: "Razão social do fiador"
                                        },
                                        model: {
                                          value:
                                            _vm.representanteOuFiador
                                              .NomeEmpresa,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.representanteOuFiador,
                                              "NomeEmpresa",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "representanteOuFiador.NomeEmpresa"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ],
                                          staticClass: "text-danger pl-3"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(errors[0]) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2528563664
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass:
                            "add-guarantor-pj-popup-content-body-add-rep",
                          attrs: { type: "primary" },
                          on: { click: _vm.exibirAdcRepParaFiadorPJ }
                        },
                        [_vm._v("Adicionar representante")]
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "el-dialog",
                  {
                    staticClass: "add-guarantor-pj-add-rep-popup",
                    attrs: {
                      width: "28%",
                      visible: _vm.popupAdcRepParaFiadorPJ,
                      "before-close": _vm.voltarParaPopupAdcFiadorPJ,
                      "append-to-body": ""
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.popupAdcRepParaFiadorPJ = $event
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "add-guarantor-pj-add-rep-popup-content" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "add-guarantor-pj-add-rep-popup-content-header"
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/ccg-icons/add-icon-ccg.svg"),
                                alt: "Ícone de adição",
                                width: "24",
                                height: "24"
                              }
                            }),
                            _c("h3", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    "Adicionar " +
                                      _vm.representanteOuFiador.TipoParteName.toLowerCase()
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "add-guarantor-pj-add-rep-popup-content-body"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "add-guarantor-pj-add-rep-popup-content-body-input"
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "CPF do representante do fiador",
                                    rules: "required|cpf"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for:
                                                    "guarantor-representative-cpf"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "CPF DO REPRESENTANTE DO FIADOR"
                                                )
                                              ]
                                            ),
                                            _c("el-input", {
                                              directives: [
                                                {
                                                  name: "mask",
                                                  rawName: "v-mask",
                                                  value: ["###.###.###-##"],
                                                  expression:
                                                    "['###.###.###-##']"
                                                }
                                              ],
                                              staticClass: "w-full",
                                              attrs: {
                                                id:
                                                  "guarantor-representative-cpf",
                                                size: "large",
                                                label:
                                                  "CPF do representante do fiador"
                                              },
                                              model: {
                                                value:
                                                  _vm.representanteOuFiador.CPF,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.representanteOuFiador,
                                                    "CPF",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "representanteOuFiador.CPF"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ],
                                                staticClass: "text-danger pl-3"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(errors[0]) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    169263752
                                  )
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "add-guarantor-pj-add-rep-popup-content-body-input"
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "nome do representante do fiador",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for:
                                                    "guarantor-representative-name"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "NOME DO REPRESENTANTE DO FIADOR"
                                                )
                                              ]
                                            ),
                                            _c("el-input", {
                                              staticClass: "w-full",
                                              attrs: {
                                                id:
                                                  "guarantor-representative-name",
                                                size: "large",
                                                label:
                                                  "nome do representante do fiador"
                                              },
                                              model: {
                                                value:
                                                  _vm.representanteOuFiador
                                                    .Nome,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.representanteOuFiador,
                                                    "Nome",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "representanteOuFiador.Nome"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ],
                                                staticClass: "text-danger pl-3"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(errors[0]) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1313587917
                                  )
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "add-guarantor-pj-add-rep-popup-content-body-input"
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "e-mail do representante do fiador",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "representative-email"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "E-MAIL DO REPRESENTANTE DO FIADOR"
                                                )
                                              ]
                                            ),
                                            _c("el-input", {
                                              staticClass: "w-full",
                                              attrs: {
                                                size: "large",
                                                label:
                                                  "e-mail do representante do fiador"
                                              },
                                              model: {
                                                value:
                                                  _vm.representanteOuFiador
                                                    .Email,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.representanteOuFiador,
                                                    "Email",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "representanteOuFiador.Email"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]"
                                                  }
                                                ],
                                                staticClass: "text-danger pl-3"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(errors[0]) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1719752769
                                  )
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "add-guarantor-pj-add-rep-popup-footer"
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass:
                                  "add-guarantor-pj-add-rep-popup-footer-btn-pj-guarantor",
                                attrs: { type: "primary" },
                                on: { click: _vm.voltarParaPopupAdcFiadorPJ }
                              },
                              [_vm._v("Adicionar representante")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer add-guarantor-pj-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: _vm.desabilitarAdicionarFiadorPJ
                        },
                        on: { click: _vm.verficarSeParteJaCadastrada }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              "Adicionar " +
                                _vm.representanteOuFiador.TipoParteName.toLowerCase()
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                staticClass: "edit-guarantor-pj-popup",
                attrs: { width: "28%", visible: _vm.popupEdtFiadorPj },
                on: {
                  "update:visible": function($event) {
                    _vm.popupEdtFiadorPj = $event
                  }
                }
              },
              [
                _c("div", { staticClass: "edit-guarantor-pj-popup-content" }, [
                  _c(
                    "div",
                    { staticClass: "edit-guarantor-pj-popup-content-header" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/icons/ccg-icons/edit-icon-ccg.svg"),
                          alt: "Ícone de edição",
                          width: "24",
                          height: "24"
                        }
                      }),
                      _c("h3", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              "Editar " +
                                _vm.representanteOuFiador.TipoParteName.toLowerCase()
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "edit-guarantor-pj-popup-content-body" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "edit-guarantor-pj-popup-content-body-input"
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "CNPJ do fiador",
                              rules: "required|cnpj"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "label",
                                        { attrs: { for: "guarantor-cnpj" } },
                                        [_vm._v("CNPJ DO FIADOR")]
                                      ),
                                      _c("el-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: ["##.###.###/####-##"],
                                            expression: "['##.###.###/####-##']"
                                          }
                                        ],
                                        staticClass: "w-full",
                                        attrs: {
                                          id: "guarantor-cnpj",
                                          size: "large",
                                          label: "CNPJ do fiador",
                                          disabled: _vm.editarFiadorPJ
                                            ? true
                                            : false
                                        },
                                        model: {
                                          value: _vm.representanteOuFiador.CNPJ,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.representanteOuFiador,
                                              "CNPJ",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "representanteOuFiador.CNPJ"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ],
                                          staticClass: "text-danger pl-3"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(errors[0]) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1570400864
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "edit-guarantor-pj-popup-content-body-input"
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "razao social do fiador",
                              rules: "required"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "guarantor-corporate-name"
                                          }
                                        },
                                        [_vm._v("RAZÃO SOCIAL DO FIADOR")]
                                      ),
                                      _c("el-input", {
                                        staticClass: "w-full",
                                        attrs: {
                                          id: "guarantor-corporate-name",
                                          size: "large",
                                          label: "razao social do fiador"
                                        },
                                        model: {
                                          value:
                                            _vm.representanteOuFiador
                                              .NomeEmpresa,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.representanteOuFiador,
                                              "NomeEmpresa",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "representanteOuFiador.NomeEmpresa"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ],
                                          staticClass: "text-danger pl-3"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(errors[0]) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              367379506
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "edit-guarantor-pj-popup-content-body-input"
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "CPF do representante do fiador",
                              rules: "required|cpf"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "guarantor-representative-cpf"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "CPF DO REPRESENTANTE DO FIADOR"
                                          )
                                        ]
                                      ),
                                      _c("el-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: ["###.###.###-##"],
                                            expression: "['###.###.###-##']"
                                          }
                                        ],
                                        staticClass: "w-full",
                                        attrs: {
                                          id: "guarantor-representative-cpf",
                                          size: "large",
                                          label:
                                            "CPF do representante do fiador",
                                          disabled:
                                            _vm.CPFRepresentanteFiadorPJDesabilitado
                                        },
                                        model: {
                                          value: _vm.representanteOuFiador.CPF,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.representanteOuFiador,
                                              "CPF",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "representanteOuFiador.CPF"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ],
                                          staticClass: "text-danger pl-3"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(errors[0]) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1641487319
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "edit-guarantor-pj-popup-content-body-input"
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "nome do representante do fiador",
                              rules: "required"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "guarantor-representative-name"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "NOME DO REPRESENTANTE DO FIADOR"
                                          )
                                        ]
                                      ),
                                      _c("el-input", {
                                        staticClass: "w-full",
                                        attrs: {
                                          id: "guarantor-representative-name",
                                          size: "large",
                                          label:
                                            "nome do representante do fiador"
                                        },
                                        model: {
                                          value: _vm.representanteOuFiador.Nome,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.representanteOuFiador,
                                              "Nome",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "representanteOuFiador.Nome"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ],
                                          staticClass: "text-danger pl-3"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(errors[0]) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2274195277
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "edit-guarantor-pj-popup-content-body-input"
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "e-mail do representante do fiador",
                              rules: "required"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "label",
                                        {
                                          attrs: { for: "representative-email" }
                                        },
                                        [
                                          _vm._v(
                                            "E-MAIL DO REPRESENTANTE DO FIADOR"
                                          )
                                        ]
                                      ),
                                      _c("el-input", {
                                        staticClass: "w-full",
                                        attrs: {
                                          size: "large",
                                          label:
                                            "e-mail do representante do fiador"
                                        },
                                        model: {
                                          value:
                                            _vm.representanteOuFiador.Email,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.representanteOuFiador,
                                              "Email",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "representanteOuFiador.Email"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ],
                                          staticClass: "text-danger pl-3"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(errors[0]) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2253069505
                            )
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer edit-guarantor-pj-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: _vm.desabilitarEditarFiadorPJ
                        },
                        on: { click: _vm.lidarEditarParte }
                      },
                      [_vm._v("Salvar representante\n        ")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "add-guarantor-pj-feedback-popup",
                attrs: {
                  width: "28%",
                  visible: _vm.popupFeedbackAdcEdtFiadorPJ,
                  "before-close": _vm.fecharAdcEdtFeedbackPopupFiadorPj
                },
                on: {
                  "update:visible": function($event) {
                    _vm.popupFeedbackAdcEdtFiadorPJ = $event
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "add-guarantor-pj-feedback-popup-content" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "add-guarantor-pj-feedback-popup-content-header"
                      },
                      [
                        !_vm.editarFiadorPJ
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/ccg-icons/add-icon-ccg.svg"),
                                alt: "Ícone de adição",
                                width: "24",
                                height: "24"
                              }
                            })
                          : _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/ccg-icons/edit-icon-ccg.svg"),
                                alt: "Ícone de edição",
                                width: "24",
                                height: "24"
                              }
                            }),
                        _c("h3", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.editarFiadorPJ
                                  ? "Editar " +
                                      _vm.representanteOuFiador.TipoParteName.toLowerCase()
                                  : "Adicionar " +
                                      _vm.representanteOuFiador.TipoParteName.toLowerCase()
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "add-guarantor-pj-feedback-popup-content-body"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "add-guarantor-pj-feedback-popup-content-body-title"
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/ccg-icons/success-icon.svg"),
                                alt: "Ícone de sucesso para edição de dados",
                                width: "48",
                                height: "48"
                              }
                            }),
                            _c("p", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.editarFiadorPJ
                                      ? _vm.representanteOuFiador.TipoParteName.toLowerCase() +
                                          " editado"
                                      : _vm.representanteOuFiador.TipoParteName.toLowerCase() +
                                          " adicionado"
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "add-guarantor-pj-feedback-popup-content-body-infos"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "add-guarantor-pj-feedback-popup-content-body-infos-list"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "add-guarantor-pj-feedback-popup-content-body-infos-divider"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-guarantor-pj-feedback-popup-content-body-infos-title"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  CNPJ DO FIADOR\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-guarantor-pj-feedback-popup-content-body-infos-content"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.representanteOuFiador.CNPJ
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "add-guarantor-pj-feedback-popup-content-body-infos-divider"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-guarantor-pj-feedback-popup-content-body-infos-title"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  RAZÃO SOCIAL DO FIADOR\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-guarantor-pj-feedback-popup-content-body-infos-content"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.representanteOuFiador
                                                .NomeEmpresa
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "add-guarantor-pj-feedback-popup-content-body-infos-divider"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-guarantor-pj-feedback-popup-content-body-infos-title"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  CPF DO REPRESENTANTE DO FIADOR\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-guarantor-pj-feedback-popup-content-body-infos-content"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.representanteOuFiador.CPF
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "add-guarantor-pj-feedback-popup-content-body-infos-divider"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-guarantor-pj-feedback-popup-content-body-infos-title"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  NOME DO REPRESENTANTE DO FIADOR\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-guarantor-pj-feedback-popup-content-body-infos-content"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.representanteOuFiador.Nome
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "add-guarantor-pj-feedback-popup-content-body-infos-divider"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-guarantor-pj-feedback-popup-content-body-infos-title"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  EMAIL DO REPRESENTANTE DO FIADOR\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "add-guarantor-pj-feedback-popup-content-body-infos-content"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.representanteOuFiador.Email
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "dialog-footer add-guarantor-pj-feedback-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.fecharAdcEdtFeedbackPopupFiadorPj }
                      },
                      [_vm._v("Fechar\n        ")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "delete-rep-popup",
                attrs: {
                  visible: _vm.popupExcluirParte,
                  width: "28%",
                  "before-close": _vm.fecharPopupExcluirParte
                },
                on: {
                  "update:visible": function($event) {
                    _vm.popupExcluirParte = $event
                  }
                }
              },
              [
                _c("div", { staticClass: "delete-rep-popup-content" }, [
                  _c(
                    "div",
                    { staticClass: "delete-rep-popup-content-header" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/icons/ccg-icons/trash-ccg.svg"),
                          alt: "Ícone de excluir representante",
                          width: "24",
                          height: "24"
                        }
                      }),
                      _c("h3", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              "Excluir " +
                                _vm.parteASerExcluida.TipoParteName.toLowerCase()
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "delete-rep-popup-content-body" }, [
                    _c(
                      "p",
                      { staticClass: "delete-rep-popup-content-body-title" },
                      [
                        _vm._v(
                          "\n            Você tem certeza que deseja\n            "
                        ),
                        _c("strong", [
                          _vm._v(
                            "excluir este\n              " +
                              _vm._s(
                                "" +
                                  _vm.parteASerExcluida.TipoParteName.toLowerCase()
                              ) +
                              "?"
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer delete-rep-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      { on: { click: _vm.fecharPopupExcluirParte } },
                      [_vm._v("Cancelar")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: _vm.desabilitarExcluirParte
                        },
                        on: { click: _vm.lidarExcluirParte }
                      },
                      [_vm._v("Excluir")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "delete-rep-feedback-popup",
                attrs: {
                  visible: _vm.popupFeedbackExcluirParte,
                  width: "28%",
                  "before-close": _vm.fecharFeedbackPopupExcluirParte
                },
                on: {
                  "update:visible": function($event) {
                    _vm.popupFeedbackExcluirParte = $event
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "delete-rep-feedback-popup-content" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "delete-rep-feedback-popup-content-header"
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/icons/ccg-icons/trash-ccg.svg"),
                            alt: "Ícone de excluir representante",
                            width: "24",
                            height: "24"
                          }
                        }),
                        _c("h3", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                "Excluir " +
                                  _vm.parteASerExcluida.TipoParteName.toLowerCase()
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "delete-rep-feedback-popup-content-body" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "delete-rep-feedback-popup-content-body-title"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.parteASerExcluida.TipoParteName +
                                    " excluido(a) com sucesso"
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "dialog-footer delete-rep-feedback-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      { on: { click: _vm.fecharFeedbackPopupExcluirParte } },
                      [_vm._v("Fechar")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "sign-link-popup",
                attrs: {
                  visible: _vm.popupLinkAssinatura,
                  width: "32%",
                  "before-close": _vm.fecharPopupLinkAssinatura
                },
                on: {
                  "update:visible": function($event) {
                    _vm.popupLinkAssinatura = $event
                  }
                }
              },
              [
                _c("div", { staticClass: "sign-link-popup-content" }, [
                  _c("div", { staticClass: "sign-link-popup-content-header" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/icons/ccg-icons/link-icon-ccg.svg"),
                        alt: "Ícone de link individual de assinatura",
                        width: "24",
                        height: "24"
                      }
                    }),
                    _c("h3", [
                      _vm._v(
                        "\n            Link de assinatura copiado\n          "
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "sign-link-popup-content-body" },
                    [
                      _c(
                        "el-alert",
                        {
                          staticClass: "sign-link-popup-content-body-alert",
                          attrs: {
                            type: "warning",
                            center: "",
                            closable: false
                          }
                        },
                        [
                          _vm._v(
                            "\n            O link único de assinatura pode ser usado para acessar e assinar o\n            documento.\n            "
                          ),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            "\n            Não compartilhe este link com mais de um signatário!"
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer sign-link-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.fecharPopupLinkAssinatura }
                      },
                      [_vm._v("Fechar")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "resend-email-popup",
                attrs: {
                  visible: _vm.popupFeedbackReenvioEmailIndividual,
                  width: "27%",
                  "before-close": _vm.fecharPopupReenvioEmailIndividual
                },
                on: {
                  "update:visible": function($event) {
                    _vm.popupFeedbackReenvioEmailIndividual = $event
                  }
                }
              },
              [
                _c("div", { staticClass: "resend-email-popup-content" }, [
                  _c(
                    "div",
                    { staticClass: "resend-email-popup-content-header" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/icons/ccg-icons/forward-to-inbox-ccg.svg"),
                          alt: "Ícone de reenviar de email",
                          width: "24",
                          height: "24"
                        }
                      }),
                      _c("h3", [
                        _vm._v("\n            Reenviar e-mail\n          ")
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "resend-email-popup-content-body" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "resend-email-popup-content-body-title"
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/icons/ccg-icons/success-icon.svg"),
                              alt: "Ícone de sucesso para edição de dados",
                              width: "48",
                              height: "48"
                            }
                          }),
                          _c("p", [
                            _vm._v(
                              "\n              O e-mail foi reenviado.\n            "
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer resend-email-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.fecharPopupReenvioEmailIndividual }
                      },
                      [_vm._v("Fechar")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "resend-email-popup",
                attrs: {
                  visible: _vm.popupFeedbackReenvioEmailTodos,
                  width: "27%",
                  "before-close": _vm.fecharPopupReenvioEmailTodos
                },
                on: {
                  "update:visible": function($event) {
                    _vm.popupFeedbackReenvioEmailTodos = $event
                  }
                }
              },
              [
                _c("div", { staticClass: "resend-email-popup-content" }, [
                  _c(
                    "div",
                    { staticClass: "resend-email-popup-content-header" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/icons/ccg-icons/forward-to-inbox-ccg.svg"),
                          alt: "Ícone de reenviar de email",
                          width: "24",
                          height: "24"
                        }
                      }),
                      _c("h3", [
                        _vm._v("\n            Reenviar e-mails\n          ")
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "resend-email-popup-content-body" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "resend-email-popup-content-body-title"
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/icons/ccg-icons/success-icon.svg"),
                              alt: "Ícone de sucesso para edição de dados",
                              width: "48",
                              height: "48"
                            }
                          }),
                          _c("p", [
                            _vm._v(
                              "\n              Todos os emails foram reenviados.\n            "
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer resend-email-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.fecharPopupReenvioEmailTodos }
                      },
                      [_vm._v("Fechar")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "resp-term-popup",
                attrs: {
                  visible: _vm.popupTermoResponsabilidade,
                  width: "35%",
                  "before-close": _vm.fecharTermoResponsabilidade
                },
                on: {
                  "update:visible": function($event) {
                    _vm.popupTermoResponsabilidade = $event
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "resp-term-popup-content" },
                  [
                    _c(
                      "div",
                      { staticClass: "resp-term-popup-content-header" },
                      [
                        _c("h3", [
                          _vm._v(
                            "\n            Termo de responsabilidade\n          "
                          )
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "resp-term-popup-content-body" }, [
                      _c("div", {
                        staticClass: "resp-term-popup-content-body-title",
                        domProps: {
                          innerHTML: _vm._s(_vm.termoDeResponsabilidade.Texto)
                        }
                      })
                    ]),
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "resp-term-popup-content-body-checkbox",
                        model: {
                          value: _vm.aceiteTermoResponsabilidade,
                          callback: function($$v) {
                            _vm.aceiteTermoResponsabilidade = $$v
                          },
                          expression: "aceiteTermoResponsabilidade"
                        }
                      },
                      [
                        _vm._v(
                          "\n          Declaro que li e estou ciente das condições acima.\n        "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer resp-term-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      { on: { click: _vm.fecharTermoResponsabilidade } },
                      [_vm._v("Cancelar")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: !_vm.aceiteTermoResponsabilidade
                        },
                        on: { click: _vm.aceitarTermoResponsabilidade }
                      },
                      [_vm._v("Assinar CCG")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "cancellation-submission-signature-popup",
                attrs: {
                  visible: _vm.popupConfirmacaoCancelamentoEnvioAss,
                  width: "32%",
                  "before-close": _vm.fecharPopupConfirmacaoCancelamentoEnvioAss
                },
                on: {
                  "update:visible": function($event) {
                    _vm.popupConfirmacaoCancelamentoEnvioAss = $event
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "cancellation-submission-signature-popup-content"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "cancellation-submission-signature-popup-content-header"
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/icons/ccg-icons/trash-ccg.svg"),
                            alt:
                              "Ícone destinado ao cancelamento de envio para assinatura",
                            width: "24",
                            height: "24"
                          }
                        }),
                        _c("h3", [
                          _vm._v(
                            "\n            Cancelamento de envio para assinatura\n          "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "cancellation-submission-signature-popup-content-body"
                      },
                      [
                        _c(
                          "el-alert",
                          {
                            staticClass:
                              "cancellation-submission-signature-popup-content-body-alert",
                            attrs: {
                              type: "warning",
                              center: "",
                              closable: false
                            }
                          },
                          [
                            _vm._v(
                              "\n            O cancelamento do envio para assinatura irá alterar o status do\n            contrato e das partes nele existente.\n            "
                            ),
                            _c("br"),
                            _c("br"),
                            _vm._v("\n            Deseja continuar?")
                          ]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "dialog-footer cancellation-submission-signature-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.cancelarEnvioParaAssinatura }
                      },
                      [_vm._v("Sim, continuar")]
                    ),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: _vm.fecharPopupConfirmacaoCancelamentoEnvioAss
                        }
                      },
                      [_vm._v("Cancelar")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "cancellation-submission-signature-feedback-popup",
                attrs: {
                  visible: _vm.popupFeedbackConfirmacaoCancelamentoEnvioAss,
                  width: "27%",
                  "before-close":
                    _vm.fecharPopupFeedbackConfirmacaoCancelamentoEnvioAss
                },
                on: {
                  "update:visible": function($event) {
                    _vm.popupFeedbackConfirmacaoCancelamentoEnvioAss = $event
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "cancellation-submission-signature-feedback-popup-content"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "cancellation-submission-signature-feedback-popup-content-header"
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/icons/ccg-icons/trash-ccg.svg"),
                            alt:
                              "Ícone destinado ao cancelamento de envio para assinatura",
                            width: "24",
                            height: "24"
                          }
                        }),
                        _c("h3", [
                          _vm._v(
                            "\n            Cancelamento de envio para assinatura\n          "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "cancellation-submission-signature-feedback-popup-content-body"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "cancellation-submission-signature-feedback-popup-content-body-title"
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/ccg-icons/success-icon.svg"),
                                alt: "Ícone de sucesso para edição de dados",
                                width: "48",
                                height: "48"
                              }
                            }),
                            _c("p", [
                              _vm._v(
                                "\n              O envio do CCG para Assinatura foi cancelado com sucesso!\n            "
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "dialog-footer cancellation-submission-signature-feedback-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click:
                            _vm.fecharPopupFeedbackConfirmacaoCancelamentoEnvioAss
                        }
                      },
                      [_vm._v("Fechar")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "cancellation-signed-ccg-popup",
                attrs: {
                  visible: _vm.popupConfirmacaoCancelamentoCCGAssinado,
                  width: "32%",
                  "before-close":
                    _vm.fecharPopupConfirmacaoCancelamentoCCGAssinado
                },
                on: {
                  "update:visible": function($event) {
                    _vm.popupConfirmacaoCancelamentoCCGAssinado = $event
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "cancellation-signed-ccg-popup-content" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "cancellation-signed-ccg-popup-content-header"
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/icons/ccg-icons/trash-ccg.svg"),
                            alt:
                              "Ícone destinado ao cancelamento de envio para assinatura",
                            width: "24",
                            height: "24"
                          }
                        }),
                        _c("h3", [
                          _vm._v(
                            "\n            Cancelamento de CCG assinado\n          "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "cancellation-signed-ccg-popup-content-body"
                      },
                      [
                        _c(
                          "el-alert",
                          {
                            staticClass:
                              "cancellation-signed-ccg-popup-content-body-alert",
                            attrs: {
                              type: "warning",
                              center: "",
                              closable: false
                            }
                          },
                          [
                            _vm._v(
                              "\n            O cancelamento do CCG assinado irá alterar o status do\n            contrato e das partes nele existente.\n            "
                            ),
                            _c("br"),
                            _c("br"),
                            _vm._v("\n            Deseja continuar?")
                          ]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "dialog-footer cancellation-signed-ccg-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.cancelarCCGAssinado }
                      },
                      [_vm._v("Sim, continuar")]
                    ),
                    _c(
                      "el-button",
                      {
                        on: {
                          click:
                            _vm.fecharPopupConfirmacaoCancelamentoCCGAssinado
                        }
                      },
                      [_vm._v("Cancelar")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "cancellation-signed-ccg-feedback-popup",
                attrs: {
                  visible: _vm.popupFeedbackConfirmacaoCancelamentoCCGAssinado,
                  width: "27%",
                  "before-close":
                    _vm.fecharPopupFeedbackConfirmacaoCancelamentoCCGAssinado
                },
                on: {
                  "update:visible": function($event) {
                    _vm.popupFeedbackConfirmacaoCancelamentoCCGAssinado = $event
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "cancellation-signed-ccg-feedback-popup-content"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "cancellation-signed-ccg-feedback-popup-content-header"
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/icons/ccg-icons/trash-ccg.svg"),
                            alt:
                              "Ícone destinado ao cancelamento de envio para assinatura",
                            width: "24",
                            height: "24"
                          }
                        }),
                        _c("h3", [
                          _vm._v(
                            "\n            Cancelamento de CCG assinado\n          "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "cancellation-signed-ccg-feedback-popup-content-body"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "cancellation-signed-ccg-feedback-popup-content-body-title"
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/ccg-icons/success-icon.svg"),
                                alt: "Ícone de sucesso para edição de dados",
                                width: "48",
                                height: "48"
                              }
                            }),
                            _c("p", [
                              _vm._v(
                                "\n              O CCG assinado foi cancelado com sucesso!\n            "
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "dialog-footer cancellation-signed-ccg-feedback-popup-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click:
                            _vm.fecharPopupFeedbackConfirmacaoCancelamentoCCGAssinado
                        }
                      },
                      [_vm._v("Fechar")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "actions text-right mt-10 w-full" },
              [
                _vm.podeReenviarEmailTodos && _vm.ccg.Contrato.StatusId == 2
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.lidarComReenviarEmailTodos }
                      },
                      [_vm._v("\n        Reenviar e-mails\n      ")]
                    )
                  : _vm._e(),
                _vm.ccg.Contrato.StatusId == 1 &&
                _vm.profileHelpers.isInsurance() &&
                _vm.podeFazerUploadTemplate
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            _vm.uploadModal = true
                          }
                        }
                      },
                      [
                        _vm._v("\n        Upload Manual CCG "),
                        _c("i", { staticClass: "el-icon-upload" })
                      ]
                    )
                  : _vm._e(),
                _vm.podeExibirRascunhoDoContrato
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.lidarDownloadDocumento }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.ccg.Contrato.StatusId === 3
                                ? "Download do documento assinado"
                                : "Download do documento para conferência"
                            ) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.podeCancelarAssinaturaCCG && _vm.ccg.Contrato.StatusId == 2
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            _vm.popupConfirmacaoCancelamentoEnvioAss = true
                          }
                        }
                      },
                      [_vm._v("\n        Cancelar envio\n      ")]
                    )
                  : _vm._e(),
                _vm.podeCancelarCcgAssinado && _vm.ccg.Contrato.StatusId == 3
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            _vm.popupConfirmacaoCancelamentoCCGAssinado = true
                          }
                        }
                      },
                      [_vm._v("\n        Cancelar CCG\n      ")]
                    )
                  : _vm._e(),
                _vm.ccg.Contrato.StatusId == 1
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: !_vm.podeEnviarCcgParaAssinatura
                        },
                        on: { click: _vm.lidarComAssinarCCG }
                      },
                      [_vm._v("\n        Assinar CCG\n      ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        : [_vm._m(0)]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto items-center mt-32"
      },
      [
        _c("span", { staticClass: "material-icons text-6xl" }, [
          _vm._v("description")
        ]),
        _c("p", [_vm._v("Contra garantia não cadastrada para esse tomador.")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }