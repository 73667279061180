var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-5 limites_taxas" },
    [
      _vm.limites.length > 0 && _vm.canViewLimits
        ? [
            _c(
              "vs-table",
              {
                attrs: {
                  data: _vm.limites,
                  noDataText: "Nenhum Limite e Taxas encontrado."
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var data = ref.data
                        return [
                          _vm._l(data, function(tr, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr },
                              [
                                _c(
                                  "vs-td",
                                  {
                                    attrs: {
                                      data: data[indextr].ModalityGroupName
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex" },
                                      [
                                        _c("span", { staticClass: "ml-20" }, [
                                          _vm._v(
                                            _vm._s(
                                              data[indextr].ModalityGroupName
                                            )
                                          )
                                        ]),
                                        _c(
                                          "vx-tooltip",
                                          {
                                            attrs: {
                                              text:
                                                data[indextr]
                                                  .ModalityGroupDescription
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "material-icons text-warning text-sm ml-2"
                                              },
                                              [_vm._v("info")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  {
                                    staticClass: "text-primary font-semibold",
                                    attrs: { data: data[indextr].LimitRevised }
                                  },
                                  [
                                    data[indextr].CanViewLimit ||
                                    _vm.podeVisualizarLimitesTaxas
                                      ? _c("p", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm._f("maskCurrency")(
                                                  data[indextr].LimitRevised
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ])
                                      : _vm._e(),
                                    _c("br"),
                                    data[indextr].ChangedManually &&
                                    !_vm.profileHelpers.isInsurance()
                                      ? _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [
                                            _vm._v(
                                              "* Limite alterado pela seguradora"
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  {
                                    staticClass: "text-primary font-semibold",
                                    attrs: { data: data[indextr].UsedLimit }
                                  },
                                  [
                                    data[indextr].CanViewLimit ||
                                    _vm.podeVisualizarLimitesTaxas
                                      ? _c("p", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm._f("maskCurrency")(
                                                  data[indextr].UsedLimit
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  {
                                    staticClass: "text-primary font-semibold",
                                    attrs: {
                                      data: data[indextr].AvailableLimit
                                    }
                                  },
                                  [
                                    data[indextr].CanViewLimit ||
                                    _vm.podeVisualizarLimitesTaxas
                                      ? _c("p", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm._f("maskCurrency")(
                                                  data[indextr].AvailableLimit
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  { attrs: { data: data[indextr].RiskRate } },
                                  [
                                    _vm.podeVisualizarLimitesTaxas ||
                                    data[indextr].CanViewLimit
                                      ? _c("p", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.replaceItem(
                                                  data[indextr].RiskRate
                                                )
                                              ) +
                                              "% /\n              " +
                                              _vm._s(
                                                _vm.replaceItem(
                                                  data[indextr].RiskRate
                                                )
                                              ) +
                                              "%\n            "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm.profileHelpers.checkPermission([
                                  _vm.profileHelpers.menuFuncionalitiesEnum
                                    .GERENCIAR_TOMADORES_SEGURADORA
                                ])
                                  ? _c(
                                      "vs-td",
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            attrs: {
                                              type: "filled",
                                              color: "primary",
                                              icon: "edit"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.editarGrupoModalidade(
                                                  data[indextr]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              Editar\n            "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          _c(
                            "vs-tr",
                            { attrs: { state: "warning" } },
                            [
                              _c("vs-td", [
                                _c("p", { staticClass: "font-bold" }, [
                                  _vm._v("Limite Total")
                                ])
                              ]),
                              _c("vs-td", { staticClass: "font-bold" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("maskCurrency")(
                                        _vm.limteTotalAprovado
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]),
                              _c("vs-td", { staticClass: "font-bold" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("maskCurrency")(
                                        _vm.limiteTotalUtilizado
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]),
                              _c("vs-td", { staticClass: "font-bold" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("maskCurrency")(
                                        _vm.limteDisponivelAprovado
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]),
                              _c("vs-td"),
                              _vm.podeVisualizarLimitesTaxas
                                ? _c("vs-td")
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  919424658
                )
              },
              [
                _c(
                  "template",
                  { slot: "thead" },
                  [
                    _c("vs-th", [
                      _c("span", { staticClass: "w-full text-center" }, [
                        _vm._v("Modalidades")
                      ])
                    ]),
                    _c("vs-th", [
                      _c("span", { staticClass: "w-full text-center" }, [
                        _vm._v("\n            Limite Aprovado "),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v("*")
                        ])
                      ])
                    ]),
                    _c("vs-th", [
                      _c("span", { staticClass: "w-full text-center" }, [
                        _vm._v("Limite Utilizado")
                      ])
                    ]),
                    _c("vs-th", [
                      _c("span", { staticClass: "w-full text-center" }, [
                        _vm._v("Limite Disponível")
                      ])
                    ]),
                    _c("vs-th", [
                      _c("span", { staticClass: "w-full text-center" }, [
                        _vm._v("Taxa "),
                        _c("br"),
                        _vm._v("\n            Segurado privado/público")
                      ])
                    ]),
                    _vm.profileHelpers.checkPermission([
                      _vm.profileHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_TOMADORES_SEGURADORA
                    ])
                      ? _c("vs-th", [_vm._v("\n          Ações\n        ")])
                      : _vm._e()
                  ],
                  1
                )
              ],
              2
            ),
            _vm.canRequestLimitsAndRatesReview
              ? _c("p", { staticClass: "px-32 pt-4 pb-12" }, [
                  _c("span", { staticClass: "text-warning" }, [_vm._v("*")]),
                  _vm._v(
                    "\n      Limite pré-aprovado e provisorio, pode estar aquém da capacidade do\n      tomador. Para aprovação do limite completo e definitivo é preciso a\n      inclusão da demanda e o envio da documentação financeira\n    "
                  )
                ])
              : _vm._e()
          ]
        : [_vm._m(0)],
      _vm.bailLimitData != {} &&
      _vm.bailLimitData != null &&
      _vm.hasBail &&
      _vm.profileHelpers.isInsurance() &&
      !_vm.mostrarDadosFiancaCorretor
        ? [
            _c(
              "vs-table",
              { attrs: { noDataText: "" } },
              [
                _c("template", { slot: "header" }, [
                  _c("h4", { staticClass: "px-8 pb-4 pt-6" }, [
                    _c("b", [_vm._v("Acúmulo externo")])
                  ])
                ]),
                _c(
                  "template",
                  { slot: "thead" },
                  [
                    _c("vs-th", [
                      _c("span", { staticClass: "w-full text-center" }, [
                        _vm._v("Grupo")
                      ])
                    ]),
                    _c("vs-th", [
                      _c("span", { staticClass: "w-full text-center" }, [
                        _vm._v("Limite")
                      ])
                    ]),
                    _c("vs-th", [
                      _c("span", { staticClass: "w-full text-center" }, [
                        _vm._v("Acúmulo")
                      ])
                    ]),
                    _c("vs-th", [
                      _c("span", { staticClass: "w-full text-center" }, [
                        _vm._v("Taxa")
                      ])
                    ]),
                    _vm.profileHelpers.isInsurance()
                      ? _c("vs-th", [
                          _c("span", { staticClass: "w-full text-center" }, [
                            _vm._v(" Ações ")
                          ])
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                [
                  _c(
                    "vs-tr",
                    [
                      _c("vs-td", [
                        _c("div", { staticClass: "w-full text-center" }, [
                          _c("span", [_vm._v("Fiança Locatícia")])
                        ])
                      ]),
                      _c("vs-td", [
                        _c(
                          "div",
                          { staticClass: "w-full text-center" },
                          [
                            _c("currency-input", {
                              staticClass:
                                "input-currency text-primary font-semibold",
                              attrs: { disabled: "true" },
                              model: {
                                value: _vm.bailLimitData.LimCredito,
                                callback: function($$v) {
                                  _vm.$set(_vm.bailLimitData, "LimCredito", $$v)
                                },
                                expression: "bailLimitData.LimCredito"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("vs-td", [
                        _c(
                          "div",
                          { staticClass: "w-full text-center" },
                          [
                            _c("currency-input", {
                              staticClass:
                                "input-currency text-primary font-semibold",
                              attrs: { disabled: "true" },
                              model: {
                                value: _vm.bailLimitData.Acumulo,
                                callback: function($$v) {
                                  _vm.$set(_vm.bailLimitData, "Acumulo", $$v)
                                },
                                expression: "bailLimitData.Acumulo"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("vs-td", [
                        _c("div", { staticClass: "w-full text-center" }, [
                          _c("p", [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.bailLimitData.TaxaRisco) +
                                "% /\n                " +
                                _vm._s(_vm.bailLimitData.TaxaRisco) +
                                "%\n              "
                            )
                          ])
                        ])
                      ]),
                      _vm.profileHelpers.checkPermission([
                        _vm.profileHelpers.menuFuncionalitiesEnum
                          .GERENCIAR_TOMADORES_SEGURADORA
                      ])
                        ? _c(
                            "vs-td",
                            [
                              _c(
                                "vs-button",
                                {
                                  attrs: { type: "filled", color: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.updatePolicyHolderBailLimit()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Atualizar\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              ],
              2
            ),
            _vm.mostrarDadosFiancaCorretor
              ? _c("p", { staticClass: "px-32 pt-4 pb-12" }, [
                  _c("span", { staticClass: "text-warning" }, [_vm._v("**")]),
                  _vm._v(
                    "\n      Cotações e Emissões do Ramo Fiança Locatícias não podem ser realizadas\n      através desta plataforma. Os limites e taxas disponibilizados são apenas\n      informativos. Para maiores informações entre em contato com o time comercial desta Seguradora.\n    "
                  )
                ])
              : _vm._e()
          ]
        : _vm._e(),
      (_vm.bailLimitData != {} &&
        _vm.bailLimitData != null &&
        _vm.hasBail &&
        _vm.profileHelpers.isInsurance()) ||
      _vm.mostrarDadosFiancaCorretor
        ? [
            _c(
              "vs-table",
              { attrs: { noDataText: "" } },
              [
                _c("template", { slot: "header" }, [
                  _c("h4", { staticClass: "px-8 pb-4 pt-6" }, [
                    _c("b", [_vm._v("Acúmulo externo")])
                  ])
                ]),
                _c(
                  "template",
                  { slot: "thead" },
                  [
                    _c("vs-th", [
                      _c("span", { staticClass: "w-full text-center" }, [
                        _vm._v("Modalidades")
                      ])
                    ]),
                    _c("vs-th", [
                      _c("span", { staticClass: "w-full text-center" }, [
                        _vm._v("Limite aprovado")
                      ])
                    ]),
                    _c("vs-th", [
                      _c("span", { staticClass: "w-full text-center" }, [
                        _vm._v("limite utilizado")
                      ])
                    ]),
                    _c("vs-th", [
                      _c("span", { staticClass: "w-full text-center" }, [
                        _vm._v("limite disponível")
                      ])
                    ]),
                    _c("vs-th", [
                      _c("span", { staticClass: "w-full text-center" }, [
                        _vm._v("Taxa "),
                        _c("br"),
                        _vm._v(" segurado publico/privado")
                      ])
                    ]),
                    _vm.profileHelpers.isInsurance()
                      ? _c("vs-th", [
                          _c("span", { staticClass: "w-full text-center" }, [
                            _vm._v(" Ações ")
                          ])
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                [
                  _c(
                    "vs-tr",
                    [
                      _c("vs-td", [
                        _c("div", { staticClass: "w-full text-center" }, [
                          _c("span", [_vm._v("Fiança Locatícia")])
                        ])
                      ]),
                      _c("vs-td", [
                        _c(
                          "div",
                          { staticClass: "w-full text-center" },
                          [
                            _c("currency-input", {
                              staticClass:
                                "input-currency text-primary font-semibold",
                              attrs: { disabled: "true" },
                              model: {
                                value: _vm.bailLimitData.LimCredito,
                                callback: function($$v) {
                                  _vm.$set(_vm.bailLimitData, "LimCredito", $$v)
                                },
                                expression: "bailLimitData.LimCredito"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("vs-td", [
                        _c(
                          "div",
                          { staticClass: "w-full text-center" },
                          [
                            _c("currency-input", {
                              staticClass:
                                "input-currency text-primary font-semibold",
                              attrs: { disabled: "true" },
                              model: {
                                value: _vm.bailLimitData.Acumulo,
                                callback: function($$v) {
                                  _vm.$set(_vm.bailLimitData, "Acumulo", $$v)
                                },
                                expression: "bailLimitData.Acumulo"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("vs-td", [
                        _c(
                          "div",
                          { staticClass: "w-full text-center" },
                          [
                            _c("currency-input", {
                              staticClass:
                                "input-currency text-primary font-semibold",
                              attrs: { disabled: "true" },
                              model: {
                                value: _vm.bailLimitData.Disponivel,
                                callback: function($$v) {
                                  _vm.$set(_vm.bailLimitData, "Disponivel", $$v)
                                },
                                expression: "bailLimitData.Disponivel"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("vs-td", [
                        _c("div", { staticClass: "w-full text-center" }, [
                          _c("p", [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.bailLimitData.TaxaRisco) +
                                "% /\n                " +
                                _vm._s(_vm.bailLimitData.TaxaRisco) +
                                "%\n              "
                            )
                          ])
                        ])
                      ]),
                      _vm.profileHelpers.checkPermission([
                        _vm.profileHelpers.menuFuncionalitiesEnum
                          .GERENCIAR_TOMADORES_SEGURADORA
                      ])
                        ? _c(
                            "vs-td",
                            [
                              _c(
                                "vs-button",
                                {
                                  attrs: {
                                    type: "filled",
                                    color: "primary",
                                    icon: "edit"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.editarGrupoModalidadeFianca(
                                        _vm.bailLimitData
                                      )
                                    }
                                  }
                                },
                                [_vm._v("\n              Editar\n            ")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              ],
              2
            )
          ]
        : _vm._e(),
      _c(
        "vs-popup",
        {
          staticClass: "editar-grupo-modalidade",
          attrs: {
            title: "Editar Grupo de Modalidade - Fiança Locaticia ",
            active: _vm.editarGrupoModalidadeFiancaPopup
          },
          on: {
            "update:active": function($event) {
              _vm.editarGrupoModalidadeFiancaPopup = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-1/2" },
              [
                _c("p", [_vm._v("Limite Aprovado")]),
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input large hasValue",
                  model: {
                    value: _vm.grupoModalidadeFianca.LimCredito,
                    callback: function($$v) {
                      _vm.$set(_vm.grupoModalidadeFianca, "LimCredito", $$v)
                    },
                    expression: "grupoModalidadeFianca.LimCredito"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-1/2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Taxa", size: "large" },
                  model: {
                    value: _vm.grupoModalidadeFianca.TaxaRisco,
                    callback: function($$v) {
                      _vm.$set(_vm.grupoModalidadeFianca, "TaxaRisco", $$v)
                    },
                    expression: "grupoModalidadeFianca.TaxaRisco"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "vs-row mt-5" }, [
            _c(
              "div",
              { staticClass: "vx-col text-right" },
              [
                _c(
                  "vs-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.salvarGrupoModalidadeFianca()
                      }
                    }
                  },
                  [_vm._v("\n          Salvar alterações\n        ")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "editar-grupo-modalidade",
          attrs: {
            title:
              "Editar Grupo de Modalidade - " +
              _vm.grupoModalidade.ModalityGroupName,
            active: _vm.editarGrupoModalidadePopup
          },
          on: {
            "update:active": function($event) {
              _vm.editarGrupoModalidadePopup = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-1/2" },
              [
                _c("p", [_vm._v("Limite Aprovado")]),
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input large hasValue",
                  model: {
                    value: _vm.grupoModalidade.LimitRevised,
                    callback: function($$v) {
                      _vm.$set(_vm.grupoModalidade, "LimitRevised", $$v)
                    },
                    expression: "grupoModalidade.LimitRevised"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-1/2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Taxa", size: "large" },
                  model: {
                    value: _vm.grupoModalidade.RiskRate,
                    callback: function($$v) {
                      _vm.$set(_vm.grupoModalidade, "RiskRate", $$v)
                    },
                    expression: "grupoModalidade.RiskRate"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "vs-row mt-5" }, [
            _c(
              "div",
              { staticClass: "vx-col text-right" },
              [
                _c(
                  "vs-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.salvarGrupoModalidade()
                      }
                    }
                  },
                  [_vm._v("\n          Salvar alterações\n        ")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto items-center my-32"
      },
      [
        _c("i", { staticClass: "onpoint-file-text" }),
        _c("p", { staticClass: "text-center" }, [
          _vm._v(
            "\n        Não há limites desse tomador disponíveis para visualização.\n      "
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }