var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cadastro-tomador new-tabs" }, [
    _c(
      "div",
      { staticClass: "breadcrumb mb-6" },
      [
        _c("breadcrumb", {
          attrs: {
            title: "Tomador",
            actualPage: (_vm.tomador || {}).Name || "",
            previousPage: "tomadores",
            previousPageTitle: "Lista de tomadores"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "perfil-tomador" },
      [
        !!_vm.tomador
          ? _c(
              "vs-tabs",
              { attrs: { color: "warning" } },
              [
                _c(
                  "vs-tab",
                  { attrs: { label: "Cadastro Completo" } },
                  [
                    (_vm.tomador || {}).Id
                      ? _c("dados-gerais", {
                          attrs: {
                            dadoCNAE: _vm.tomador.CNAEs[0],
                            endereco: _vm.tomador.Location,
                            telefone: _vm.tomador.Phones[0]
                          },
                          on: {
                            updateSupplementaryData: _vm.updateSupplementaryData
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "vs-tab",
                  { attrs: { label: "Filiais" } },
                  [
                    _c("filiais", {
                      attrs: {
                        uniqueIdTomador: _vm.tomador.UniqueId,
                        dadosFiliais: _vm.tomador.Branches
                      }
                    })
                  ],
                  1
                ),
                _vm.profileHelpers.checkPermission([
                  _vm.profileHelpers.menuFuncionalitiesEnum
                    .GERENCIAR_TOMADORES_SEGURADORA,
                  _vm.profileHelpers.menuFuncionalitiesEnum
                    .VISUALIZAR_TOMADORES_SEGURADORA
                ])
                  ? _c(
                      "vs-tab",
                      { attrs: { label: "Grupo Econômico" } },
                      [
                        _c("grupo-economico", {
                          attrs: { tomador: _vm.tomador }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.profileHelpers.checkPermission([
                  _vm.profileHelpers.menuFuncionalitiesEnum
                    .GERENCIAR_TOMADORES_SEGURADORA,
                  _vm.profileHelpers.menuFuncionalitiesEnum
                    .VISUALIZAR_TOMADORES_SEGURADORA
                ])
                  ? _c(
                      "vs-tab",
                      { attrs: { label: "Regras Executadas" } },
                      [
                        _c("regras-executadas", {
                          attrs: {
                            tomador: _vm.tomador,
                            personRisk: _vm.tomador.PersonRisk
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "vs-tab",
                  { attrs: { label: "Limites e Taxas" } },
                  [
                    _c("limites-taxas", {
                      attrs: {
                        tomador: _vm.tomador,
                        personRisk: _vm.tomador.PersonRisk
                      }
                    })
                  ],
                  1
                ),
                _vm.profileHelpers.checkPermission([
                  _vm.profileHelpers.menuFuncionalitiesEnum
                    .GERENCIAR_TOMADORES_SEGURADORA,
                  _vm.profileHelpers.menuFuncionalitiesEnum
                    .VISUALIZAR_TOMADORES_SEGURADORA
                ])
                  ? _c(
                      "vs-tab",
                      { attrs: { label: "Condições Comerciais" } },
                      [
                        _c("CondicoesMercado", {
                          attrs: {
                            tomador: _vm.tomador,
                            personRisk: _vm.tomador.PersonRisk
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.profileHelpers.checkPermission([
                  _vm.profileHelpers.menuFuncionalitiesEnum.GERENCIAR_CCG
                ])
                  ? _c(
                      "vs-tab",
                      { attrs: { label: "CCG" } },
                      [
                        _vm.tomador.Id
                          ? _c("CCG", { attrs: { tomador: _vm.tomador } })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "vs-tab",
                  { attrs: { label: "Apólices" } },
                  [
                    _vm.tomador.Id
                      ? _c("apolices-tomador", {
                          attrs: { tomador: _vm.tomador }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm.tomador.Id &&
                _vm.profileHelpers.checkPermission([
                  _vm.profileHelpers.menuFuncionalitiesEnum
                    .GERENCIAR_TOMADORES_SEGURADORA,
                  _vm.profileHelpers.menuFuncionalitiesEnum
                    .VISUALIZAR_TOMADORES_SEGURADORA
                ])
                  ? _c(
                      "vs-tab",
                      { attrs: { label: "Sócios / Representantes" } },
                      [
                        _vm.tomador.UniqueId
                          ? _c("TableSocios", {
                              attrs: { Socios: _vm.tomador.UniqueId }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.profileHelpers.isInsurance() &&
                _vm.profileHelpers.checkPermission([
                  _vm.profileHelpers.menuFuncionalitiesEnum
                    .VISUALIZAR_TELA_DE_DOCUMENTOS
                ])
                  ? _c(
                      "vs-tab",
                      { attrs: { label: "Documentos" } },
                      [_c("broker-documents")],
                      1
                    )
                  : _vm._e(),
                _vm.tomador.Id &&
                _vm.profileHelpers.checkPermission([
                  _vm.profileHelpers.menuFuncionalitiesEnum
                    .GERENCIAR_TOMADORES_SEGURADORA,
                  _vm.profileHelpers.menuFuncionalitiesEnum
                    .VISUALIZAR_TOMADORES_SEGURADORA
                ])
                  ? _c(
                      "vs-tab",
                      { attrs: { label: "Histórico de Alterações" } },
                      [
                        _c("historico-alteracao", {
                          attrs: { id: _vm.tomador.Id }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.tomador.Id &&
                _vm.profileHelpers.checkPermission([
                  _vm.profileHelpers.menuFuncionalitiesEnum
                    .GERENCIAR_TOMADORES_SEGURADORA,
                  _vm.profileHelpers.menuFuncionalitiesEnum
                    .VISUALIZAR_TOMADORES_SEGURADORA
                ])
                  ? _c(
                      "vs-tab",
                      { attrs: { label: "Histórico de Consultas" } },
                      [
                        _vm.exibirHistoricoConsultasTomadorPorBureau
                          ? _c("HistoricoConsultasTomador", {
                              attrs: { document: _vm.tomador.CpfCnpj }
                            })
                          : _c("historico-consultas", {
                              attrs: { document: _vm.tomador.CpfCnpj }
                            })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }